import state from "./moduleActionState"
import actions from "./moduleActionActions"
import getters from "./moduleActionGetters"
import mutations from "./moduleActionMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
