/* eslint-disable no-unused-vars */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/store"
import VueTailwind from "vue-tailwind"
import { settings } from "./vue-tailwind.js"
import socket from "./socket/socket"
Vue.use(VueTailwind, settings)

/* ACL Permission */
import acl from "@/acl/acl"
/* Styles */
import "./assets/scss/main.scss"

/* Tailwind */
import "./assets/css/tailwind.css"

/* Filters */
import "./filters/filters"

import axios from "./utils/axios"
Vue.prototype.$http = axios

import Snotify from "vue-snotify"
Vue.use(Snotify)

import VeeValidate, { Validator } from "vee-validate"
import {
  es,
  alpha_spaces_rule,
  amount_rule,
  simple_required,
  simple_email,
  phone_rule,
  alpha_symbol_rule,
  year_length
} from "./utils/dictionary"

const { diffFrom } = require("./utils/dates")

const VueValidationEs = require("vee-validate/dist/locale/es")

Vue.use(VeeValidate, {
  locale: "es",
  dictionary: {
    es: VueValidationEs
  }
})

Validator.localize(es)
Validator.extend("amount", amount_rule)
Validator.extend("alpha_spaces", alpha_spaces_rule)
Validator.extend("phone", phone_rule)
Validator.extend("alpha_symbol", alpha_symbol_rule)
Validator.extend("year_length", year_length)
Validator.extend("simple_required", simple_required, { computesRequired: true })
Validator.extend("simple_email", simple_email)

/* Vue schedule */

require("./plugins/fontawesome")
require("./globalComponents")

Vue.config.productionTip = false

Vue.prototype.$socket = socket
Vue.prototype.$diffFrom = diffFrom

new Vue({
  router,
  store,
  sockets: {
    connect: function () {
      console.log("socket connected")
    }
  },
  render: (h) => h(App)
}).$mount("#app")
