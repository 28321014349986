export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Home.vue"),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "Dashboard",
      module: "dashboard",
      breadcrumb: [
        { title: "Dashboard de la liga internacional", url: "/dashboard" }
      ],
      topColor: "#295DA4",
      icon: "tv"
    }
  }
]
