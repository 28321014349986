export default [
  {
    path: "/partidos",
    name: "Partidos",
    component: () => import("@/views/game/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Partidos",
      module: "games",
      breadcrumb: [{ title: "Partidos", url: "/partidos" }],
      topColor: "#D5A434",
      icon: "volleyball-ball"
    }
  },
  {
    path: "/partidos/crear",
    name: "Crear partido",
    component: () => import("@/views/game/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear partido",
      module: "games",
      breadcrumb: [
        { title: "Partidos", url: "/partidos" },
        { title: "Crear", url: "/partidos/crear" }
      ],
      topColor: "#D5A434",
      icon: "volleyball-ball"
    }
  },
  {
    path: "/partidos/:id/editar",
    name: "Editar partido",
    component: () => import("@/views/game/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Partidos",
      module: "games",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Partidos", url: "/partidos" },
          { title: "Editar", url: `/partidos/${route.params.id}/editar` }
        ]
      },
      topColor: "#D5A434",
      icon: "volleyball-ball"
    }
  },
  {
    path: "/resultados",
    name: "Resultados",
    component: () => import("@/views/game/ResultsList.vue"),
    meta: {
      rule: "isScorer",
      authRequired: true,
      pageTitle: "Resultados",
      module: "results",
      breadcrumb: [{ title: "Resultados", url: "/resultados" }],
      topColor: "#55B585",
      icon: "edit"
    }
  },
  {
    path: "/resultados/:id/editar",
    name: "Editar resultado",
    component: () => import("@/views/game/EditResult.vue"),
    meta: {
      rule: "isScorer",
      authRequired: true,
      pageTitle: "Resultados",
      module: "results",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Resultados", url: "/resultados" },
          { title: "Editar", url: `/resultados/${route.params.id}/editar` }
        ]
      },
      topColor: "#55B585",
      icon: "edit"
    }
  }
]
