export default {
  elements: [],
  unpaginated_elements: [],
  element: null,
  positions: {
    1: "Arquero",
    2: "Defensa",
    4: "Delantero",
    3: "Mediocampo"
  }
}
