<template>
  <div>
    <t-card>
      <template v-slot:header>
        <div class="w-full px-5 py-5">
          <div
            class="flex items-center justify-between px-5"
            v-if="tableName || seeAll"
          >
            <h1 class="font-bold text-xl" v-if="tableName">{{ tableName }}</h1>
            <t-button
              v-if="seeAll"
              variant="error"
              @click="$router.push('/' + url)"
              ><span class="font-bold text-sm text-white"
                >VER TODOS</span
              ></t-button
            >
          </div>
        </div>
      </template>
      <div class="w-full flex justify-center" v-if="paginate">
        <tfoot class="ml-auto mb-2">
          <tr>
            <td class="px-3 whitespace-no-wrap text-sm" :colspan="12">
              <t-pagination
                id="pagination-buttons"
                v-if="paginate"
                variant="rounded"
                :hide-first-last-controls="true"
                v-model="current"
                :total-items="paginate.total"
                :per-page="paginate.per_page || '10'"
                @change="changePage()"
              />
            </td>
          </tr>
        </tfoot>
      </div>

      <t-table
        :headers="headers"
        :data="filteredData"
        :responsive="true"
        :responsive-breakpoint="700"
      >
        <template
          slot="tbody"
          slot-scope="{ trClass, tdClass, renderResponsive, data, headers }"
        >
          <template v-if="renderResponsive">
            <template
              v-if="
                loadData &&
                (!paginate || (paginate && paginate.total == 0) || loadingData)
              "
            >
              <tbody>
                <no-results
                  :empty="
                    (paginate && paginate.total == 0) ||
                    (!loadingData && !data.length)
                  "
                  :loading="loadingData"
                  >{{ noResultsMessage }}</no-results
                >
              </tbody>
            </template>
            <template v-else-if="!loadData && !data.length">
              <tbody>
                <no-results :empty="true" :loading="loadingData">
                  {{ noResultsMessage }}</no-results
                >
              </tbody>
            </template>
            <template v-else>
              <tbody
                v-for="(row, rowIndex) in data"
                :key="rowIndex"
                :class="['mb-5', rowIndex % 2 === 0 ? 'bg-gray-100' : '']"
              >
                <template v-for="(col, name, index) in row">
                  <tr
                    :key="index"
                    v-if="name != 'id' && !nameExceptions.includes(name)"
                    :class="trClass"
                  >
                    <template
                      v-if="['date', 'date_from', 'date_to'].includes(name)"
                    >
                      <th class="text-sm font-semibold text-gray-600 pl-2">
                        {{ headers[index - 1].text }}
                      </th>
                      <td :class="tdClass">
                        {{ col | transformDate("reduced") }}
                      </td>
                    </template>
                    <template v-else-if="name == 'time'">
                      <th class="text-sm font-semibold text-gray-600 pl-2">
                        {{ headers[index - 1].text }}
                      </th>
                      <td :class="tdClass">{{ col | transformHour }}</td>
                    </template>

                    <template v-else-if="name == 'datetime'">
                      <th class="text-sm font-semibold text-gray-600 pl-2">
                        {{ headers[index - 1].text }}
                      </th>
                      <td :class="tdClass">
                        {{ col | transformDate("reduced") }} -
                        {{ col.substring(11) | transformHour }}
                      </td>
                    </template>

                    <template v-else-if="name == 'img_logo'">
                      <td :class="[tdClass, 'flex justify-center']">
                        <div class="h-8 w-8 mx-auto">
                          <img
                            class="object-scale-down w-full h-full"
                            style="aspect-ratio: 1"
                            :src="col"
                            alt="Image register"
                          />
                        </div>
                      </td>
                    </template>

                    <template v-else-if="name == 'image'">
                      <th class="text-sm font-semibold text-gray-600 pl-2">
                        {{ headers[index - 1].text }}
                      </th>
                      <td :class="tdClass">
                        <div class="h-8 w-8">
                          <img
                            class="rounded-full object-cover w-full h-full"
                            style="aspect-ratio: 1"
                            :src="col"
                            alt="Image register"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-else>
                      <div
                        v-on="
                          hasDetail
                            ? {
                                click: () => {
                                  let query = parameters.type
                                    ? '?type=' + parameters.type
                                    : ''
                                  $router.push(
                                    `/${url}/${row.id}/editar${query}`
                                  )
                                }
                              }
                            : hasDetail2
                            ? {
                                click: () => $router.push(`/${url}/${row.id}`)
                              }
                            : hasDetail3
                            ? {
                                click: () =>
                                  $router.push(`/${url}/${row.id}/categorias`)
                              }
                            : hasDetail4
                            ? {
                                click: () =>
                                  $router.push(`/${url}/${row.id}/academias`)
                              }
                            : hasDetail5
                            ? {
                                click: () =>
                                  $router.push(`/${url}/${row.id}/detalles`)
                              }
                            : {}
                        "
                      >
                        <th class="text-sm font-semibold text-gray-600 pl-2">
                          {{ headers[index - 1].text }}
                        </th>

                        <!-- Case for long uni-word texts -->
                        <td
                          :class="tdClass"
                          v-if="
                            col &&
                            col.length > 20 &&
                            col.split(' ').length === 1
                          "
                        >
                          <p style="word-break: break-all">
                            {{ col }}
                          </p>
                        </td>
                        <td :class="tdClass" v-else>
                          {{ col }}
                        </td>
                      </div>
                    </template>
                  </tr>
                </template>
                <td :class="[tdClass, 'flex mt-2 items-center justify-start']">
                  <button
                    v-if="
                      canChangeStatus && row.verified === 0 && row.active == 1
                    "
                    class="text-xl mx-2 my-auto"
                    :style="{ color: $route.meta.topColor }"
                    @click="changeVerified(row)"
                  >
                    <icon icon="user-check"></icon>
                    <span class="text-sm">Verificar</span>
                  </button>
                  <button
                    v-if="
                      canChangeStatus && row.verified === 0 && row.active == 0
                    "
                    class="text-xl mx-2 my-auto text-black"
                    @click="changeVerified(row)"
                  >
                    <icon icon="user-check"></icon>
                    <span class="text-sm">Verificar</span>
                  </button>
                  <button
                    v-if="canChangeStatus && row.active == 1"
                    class="text-xl mx-2 my-auto"
                    :style="{ color: $route.meta.topColor }"
                    @click="changeItemStatus(row, 0)"
                  >
                    <icon icon="check-square"></icon>
                    <span class="text-sm">Desactivar</span>
                  </button>
                  <button
                    v-if="canChangeStatus && row.active == 0"
                    class="text-xl mx-2 my-auto text-black"
                    @click="changeItemStatus(row, 1)"
                  >
                    <icon icon="minus-square"></icon>
                    <span class="text-sm">Activar</span>
                  </button>
                  <button
                    v-if="canEdit && row.active == 1"
                    class="text-xl mx-2 my-auto"
                    :style="{ color: $route.meta.topColor }"
                    @click="viewDetail(row.id)"
                  >
                    <icon icon="edit"></icon>
                    <span class="text-sm">Editar</span>
                  </button>
                  <button
                    v-if="canEdit && row.active == 0"
                    class="text-xl mx-2 my-auto text-black"
                    @click="viewDetail(row.id)"
                  >
                    <icon icon="edit"></icon>
                    <span class="text-sm">Editar</span>
                  </button>
                  <button
                    v-if="canDelete && row.active == 1"
                    class="text-xl mx-2 my-auto"
                    :style="{ color: $route.meta.topColor }"
                    @click="removeItem(row)"
                  >
                    <icon icon="trash-alt"></icon>
                    <span class="text-sm">Eliminar</span>
                  </button>
                  <button
                    v-if="canDelete && row.active == 0"
                    class="text-xl mx-2 my-auto text-black"
                    @click="removeItem(row)"
                  >
                    <icon icon="trash-alt"></icon>
                    <span class="text-sm">Eliminar</span>
                  </button>
                </td>
              </tbody>
            </template>
          </template>
          <template
            v-else-if="
              loadData &&
              ((!renderResponsive && !paginate) ||
                (paginate && paginate.total == 0) ||
                loadingData)
            "
          >
            <tbody>
              <tr>
                <td :colspan="headers.length">
                  <div>
                    <no-results
                      :empty="
                        (paginate && paginate.total == 0) ||
                        (!loadingData && !data.length)
                      "
                      :loading="loadingData"
                      >{{ noResultsMessage }}</no-results
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-else-if="!loadData && !data.length">
            <tbody>
              <tr>
                <td :colspan="headers.length">
                  <div>
                    <no-results :empty="true" :loading="loadingData">{{
                      noResultsMessage
                    }}</no-results>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </template>

        <template slot="row" slot-scope="{ trClass, tdClass, row }">
          <tr :class="[trClass]">
            <template v-for="(col, index) in row">
              <td
                :class="[tdClass, 'cursor-pointer']"
                :key="index"
                v-if="index !== 'id' && !nameExceptions.includes(index)"
                v-on="
                  hasDetail
                    ? { click: () => viewDetail(row.id) }
                    : hasDetail2
                    ? { click: () => viewDetail2(row.id) }
                    : hasDetail3
                    ? { click: () => viewDetail3(row.id) }
                    : hasDetail4
                    ? { click: () => viewDetail4(row.id) }
                    : hasDetail5
                    ? { click: () => viewDetail5(row.id) }
                    : {}
                "
              >
                <template v-if="index == 'img_logo'">
                  <div class="flex justify-center items-center h-8 w-8">
                    <img
                      class="w-full object-scale-down"
                      style="aspect-ratio: 1"
                      :src="col"
                      alt="Image register"
                    />
                  </div>
                </template>

                <template v-else-if="index == 'image'">
                  <div class="flex justify-center items-center h-12 w-12">
                    <img
                      class="w-full object-cover rounded-full"
                      style="aspect-ratio: 1"
                      :src="col"
                      alt="Image user"
                    />
                  </div>
                </template>
                <template
                  v-else-if="['date', 'date_from', 'date_to'].includes(index)"
                >
                  {{ col | transformDate("reduced") }}
                </template>
                <template v-else-if="index == 'time'">
                  {{ col | transformHour }}
                </template>
                <template v-else-if="index == 'datetime'">
                  {{ col | transformDate("reduced") }} -
                  {{ col.substring(11) | transformHour }}
                </template>

                <!-- Case for long uni-word texts -->
                <template
                  v-else-if="
                    col && col.length > 20 && col.split(' ').length === 1
                  "
                >
                  <p style="word-break: break-all">{{ col }}</p>
                </template>
                <template v-else>
                  {{ col }}
                </template>
              </td>
            </template>
            <td :class="[tdClass, 'flex items-center justify-center']">
              <t-dropdown v-if="row.verified === 0" variant="tooltipLeft">
                <button
                  v-if="row.active == 1"
                  slot="trigger"
                  class="text-xl mx-2 my-auto"
                  :style="{ color: $route.meta.topColor }"
                  @click="changeVerified(row)"
                >
                  <icon icon="user-check"></icon>
                </button>
                <button
                  v-else
                  slot="trigger"
                  class="text-xl mx-2 my-auto text-black"
                  @click="changeVerified(row)"
                >
                  <icon icon="user-check"></icon>
                </button>
                <template>Verificar</template>
              </t-dropdown>

              <t-dropdown
                v-if="canChangeStatus"
                variant="tooltipLeft"
                toggle-on-hover
              >
                <button
                  slot="trigger"
                  v-if="row.active == 1"
                  class="text-xl mx-2 my-auto"
                  :style="{ color: $route.meta.topColor }"
                  @click="changeItemStatus(row, 0)"
                >
                  <icon icon="check-square"></icon>
                </button>
                <button
                  slot="trigger"
                  v-if="row.active == 0"
                  class="text-xl mx-2 my-auto text-black"
                  @click="changeItemStatus(row, 1)"
                >
                  <icon icon="minus-square"></icon>
                </button>

                <template>
                  {{ row.active == 1 ? "Desactivar" : "Activar" }}
                </template>
              </t-dropdown>

              <t-dropdown v-if="canEdit" variant="tooltipLeft" toggle-on-hover>
                <button
                  v-if="row.active == 1"
                  slot="trigger"
                  class="text-xl mx-2 my-auto"
                  :style="{ color: $route.meta.topColor }"
                  @click="viewDetail(row.id)"
                >
                  <icon icon="edit"></icon>
                </button>
                <button
                  v-else
                  slot="trigger"
                  class="text-xl mx-2 my-auto text-black"
                  @click="viewDetail(row.id)"
                >
                  <icon icon="edit"></icon>
                </button>

                <template>Editar</template>
              </t-dropdown>

              <t-dropdown
                v-if="canDelete"
                variant="tooltipLeft"
                toggle-on-hover
              >
                <button
                  v-if="row.active == 1"
                  slot="trigger"
                  class="text-xl mx-2 my-auto"
                  :style="{ color: $route.meta.topColor }"
                  @click="removeItem(row)"
                >
                  <icon icon="trash-alt"></icon>
                </button>
                <button
                  v-else
                  slot="trigger"
                  class="text-xl mx-2 my-auto text-black"
                  @click="removeItem(row)"
                >
                  <icon icon="trash-alt"></icon>
                </button>

                <template>Eliminar</template>
              </t-dropdown>

              <t-dropdown v-if="canView" variant="tooltipLeft" toggle-on-hover>
                <button
                  v-if="(row.token = !'')"
                  slot="trigger"
                  :style="{ color: $route.meta.topColor }"
                  class="text-xl mx-2 my-auto"
                  @click="viewExternalDetail(row)"
                >
                  <icon icon="eye"></icon>
                </button>

                <template>Ver</template>
              </t-dropdown>
            </td>
          </tr>
        </template>
      </t-table>
    </t-card>

    <br /><br />
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")
export default {
  name: "basic-table",
  props: {
    url: {
      type: String
    },
    route: {
      type: String
    },
    module: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    canView: {
      type: String
      // default:null,
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    isMaster: {
      type: Boolean,
      default: false
    },
    tableName: {
      type: String,
      default: null
    },
    seeAll: {
      type: Boolean,
      default: false
    },
    parameters: {
      type: Object,
      default: null
    },
    canChangeStatus: {
      type: Boolean,
      default: true
    },
    action: {
      type: String,
      default: "global/getItems"
    },
    customDispatch: {
      type: String,
      default: null
    },
    hasDetail: {
      type: Boolean,
      default: true
    },
    hasDetail2: {
      type: Boolean,
      default: false
    },
    hasDetail3: {
      type: Boolean,
      default: false
    },
    hasDetail4: {
      type: Boolean,
      default: false
    },
    hasDetail5: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Boolean,
      default: true
    },
    noResultsMessage: {
      type: String,
      default: "No se encontraron resultados"
    }
  },
  data() {
    return {
      paginate: null,
      current: 1,
      loadingData: false,
      page: 1,
      query: "",
      nameExceptions: ["id", "active", "verified", "slug"],
      initializedColors: false
    }
  },
  computed: {
    filteredData() {
      return this.data
    },
    topColor() {
      return this.$route.meta.topColor || "#295DA4"
    }
  },
  methods: {
    viewDetail(id) {
      let query = this.parameters.type ? "?type=" + this.parameters.type : ""
      this.$router.push(`/${this.url}/${id}/editar${query}`)
    },
    viewDetail2(id) {
      this.$router.push(`/${this.url}/${id}`)
    },
    viewDetail3(id) {
      this.$router.push(`/${this.url}/${id}/categorias`)
    },
    viewDetail4(id) {
      this.$router.push(`/${this.url}/${id}/academias`)
    },
    viewDetail5(id) {
      this.$router.push(`/${this.url}/${id}/detalles`)
    },
    viewExternalDetail(row) {
      let external_url = this.canView + row.slug
      console.log(external_url)
      window.open(external_url, "_blank")
    },
    removeItem(item) {
      this.$dialog
        .confirm({
          title: "¿Eliminar?",
          text: `Está a punto de eliminar ${
            item.name ? "a " + item.name : "este elemento."
          } Esta acción no se puede deshacer.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "No",
          okButtonText: "Sí"
        })
        .then((result) => {
          result.isOk && this.changeStatus(item, 2)
        })
    },
    changeVerified(item) {
      this.$dialog
        .confirm({
          title: "¿Verificar?",
          text: `Esta acción verificará permanentemente a ${item.name}.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          result.isOk && this.verifyItem(item)
        })
    },
    changeItemStatus(item, status) {
      this.$dialog
        .confirm({
          title: item.active == 1 ? "¿Desactivar?" : "¿Activar?",
          text: item.active
            ? `Esta acción desactivará temporalmente ${
                item.name ? "a " + item.name : "este elemento"
              }, podrá deshacer esta acción activándolo nuevamente.`
            : `Esta acción activará nuevamente ${
                item.name ? " a " + item.name : "este elemento"
              }.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          result.isOk && this.changeStatus(item, status)
        })
    },
    verifyItem(item) {
      this.$store
        .dispatch("global/update", {
          payload: {
            id: item.id,
            verified: 1
          },
          route: this.route + "/update",
          module: this.module
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            this.$store.dispatch("global/updateField", {
              id: item.id,
              field: "verified",
              value: 1,
              module: this.module
            })
          }
          notify().then(({ notification }) => {
            notification("master-verify", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    changeStatus(item, status) {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: item.id,
            status
          },
          route: this.isMaster ? "/master" + this.route : this.route,
          module: this.module
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            if ([0, 1].includes(status)) {
              if (status === 0) this.$emit("deactivate", this.index)
              else if (status === 1) this.$emit("activate", this.index)

              this.$store.dispatch("global/updateField", {
                id: item.id,
                field: "active",
                value: status,
                module: this.module
              })
              notify().then(({ notification }) => {
                notification("master-status", response.code, this.$snotify)
              })
            } else {
              this.$emit("erase")
              this.getItems()
              notify().then(({ notification }) => {
                notification(
                  "master-status-delete",
                  response.code,
                  this.$snotify
                )
              })
            }
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    async getItems() {
      this.loadingData = true
      let form = {
        route: `${this.route}/all`,
        module: this.module,
        isMaster: this.isMaster
      }

      if (this.parameters) {
        form.params = { ...this.parameters }
      } else {
        form.params = {}
      }
      form.params.page = this.current || 1
      if (!form.params.order_key && !form.params.order_value) {
        form.params.order_key = "id"
        form.params.order_value = "desc"
      }

      if (this.query && this.query !== "") {
        form.params.query = this.query
      }

      await this.$store
        .dispatch(this.action, form)
        .then((result) => {
          if (result.data.meta) {
            if (this.customDispatch)
              this.$store.dispatch(this.customDispatch, result.data.data)
            this.paginate = result.data.meta
          } else {
            if (this.customDispatch)
              this.$store.dispatch(this.customDispatch, result.data)
          }
          this.loadingData = false
        })
        .catch((error) => {
          console.log(error)
          this.loadingData = false
        })
    },
    changePage() {
      this.getItems()
    },
    changeQuery(value) {
      this.query = value
      this.current = 1
      this.getItems()
    }
  },
  created() {
    if (this.loadData) this.getItems()
  },
  watch: {
    parameters: {
      handler() {
        this.getItems()
      },
      deep: true
    },
    paginate: {
      handler() {
        if (this.paginate) {
          this.$nextTick(() => {
            const activeBtn = Array.from(
              document.getElementsByClassName("pagActiveBtn")
            )
            if (activeBtn.length > 0) {
              activeBtn[0].style.setProperty("background-color", this.topColor)
              activeBtn[0].classList.add("underline")
            }
            const inactiveBtns = Array.from(
              document.getElementsByClassName("pagInactiveBtn")
            )

            for (let index in inactiveBtns) {
              inactiveBtns[index].style.setProperty(
                "background-color",
                this.topColor
              )
            }
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
table {
  min-height: 6.5rem !important;
}
@media screen and (max-width: 700px) {
  tfoot > tr {
    width: 100% !important;
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
  }

  table,
  tbody {
    display: flex;
    flex-direction: column;
  }
  tbody {
    margin-bottom: 0.5rem;
    border-radius: 7px;
    border: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background: #fff !important;
  }
  table {
    background: transparent !important;
    border: none;
    box-shadow: none;
    --tw-shadow: none !important;
  }
}
@media screen and (max-width: 350px) {
  tr > * {
    display: flex;
    flex-direction: column;
  }
  tr > * > * {
    margin: 0 auto;
  }
}
</style>
