<template>
  <div
    :style="`height: ${height}`"
    class="cursor-pointer relative w-full h-full text-center flex justify-center items-center flex-col rounded-lg overflow-hidden p-2"
    :class="backgroundColor"
  >
    <div
      class="h-full w-full flex items-center justify-center"
      @click="showModal = true"
    >
      <div class="flex items-center justify-center">
        <div v-if="!viewExternal && !viewLocal">
          <icon icon="upload" class="text-primary-800 text-xl" />
          <div>Selecciona una imagen</div>
        </div>
        <img
          v-if="viewLocal"
          :src="image"
          class="object-scale-down h-full my-auto"
        />
        <img
          v-else-if="viewExternal"
          :src="externalUrl"
          class="object-scale-down w-full h-full"
          style="filter: drop-shadow(0 0 0.2rem #a0a0a0)"
        />
      </div>
    </div>

    <t-modal
      :header="title"
      :value="showModal"
      @closed="onClosed"
      variant="xl"
      @before-close="beforeClose"
      :clickToClose="false"
    >
      <!-- t-upload-file clone -->

      <div
        v-show="!base64Img"
        :style="`height: ${height}`"
        class="mx-auto cursor-pointer relative w-3/4 h-full text-center flex justify-center items-center flex-col rounded-lg overflow-hidden p-3 border border-b"
        :class="backgroundColor"
      >
        <input
          type="file"
          name="file"
          accept=".jpg, .jpeg, .png, .gif, .tiff"
          class="absolute top-0 bottom-0 right-0 left-0 z-10 h-full w-full opacity-0 cursor-pointer"
          @change="getImage($event)"
          ref="file"
        />
        <icon icon="upload" class="text-primary-800 text-xl" />
        <div>Haz clic aquí para seleccionar una imagen</div>
      </div>

      <!-- end of t-upload-file clone -->

      <cropper
        class="cropper"
        :src="base64Img"
        :stencil-props="{
          aspectRatio
        }"
        @change="change"
      />

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            v-if="base64Img"
            variant="gray"
            type="button"
            @click="resetPicture"
            class="mx-1"
            >Restablecer</t-button
          >
          <t-button
            variant="error"
            type="button"
            @click="showModal = false"
            class="mx-1"
            >Cancelar</t-button
          >

          <t-button
            type="button"
            :disabled="isLoading"
            @click="updatePicture"
            class="mx-1"
            >Guardar
            <span v-if="isLoading">
              <t-loader extraClass="ml-1 text-white my-auto"
            /></span>
          </t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"

export default {
  name: "cropper-upload",
  data() {
    return {
      image: null,
      base64Img: null,
      showModal: false,
      isLoading: false,
      newFile: null,
      viewLocal: false,
      viewExternal: false
    }
  },
  components: {
    Cropper
  },
  props: {
    file: {
      type: String | File
    },
    title: {
      type: String,
      required: true
    },
    aspectRatio: {
      type: Number,
      required: true
    },
    height: {
      type: String,
      default: "150px"
    },
    backgroundColor: {
      type: String,
      default: "bg-gray-100"
    },
    externalUrl: {
      type: String,
      default: null
    }
  },

  methods: {
    getImage(event) {
      let data = event.target.files[0]
      this.name = data.name
      this.newFile = event.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        this.base64Img = e.target.result
      }
    },
    resetPicture() {
      this.base64Img = null
    },
    beforeClose() {
      this.base64Img = null
    },
    onClosed() {
      this.showModal = false
    },
    change({ canvas }) {
      canvas.toBlob((blob) => {
        const file = new File([blob], Date.now() + ".png")
        this.newFile = file
      })
    },
    updatePicture() {
      this.$emit("update:file", this.newFile)
      let reader = new FileReader()
      reader.readAsDataURL(this.newFile)
      reader.onload = (e) => {
        this.image = e.target.result
        this.viewLocal = true
      }
      this.showModal = false
    }
  },
  mounted() {
    if (this.externalUrl) {
      this.viewExternal = true
    }
  },
  watch: {
    externalUrl() {
      if (this.externalUrl) {
        this.viewExternal = true
      }
    }
  }
}
</script>

<style lang="scss">
.cropper {
  max-height: 70vh;
}
</style>
