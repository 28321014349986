<template>
  <div
    class="cursor-pointer relative text-center flex justify-center items-center flex-col rounded-lg overflow-hidden"
  >
    <icon
      icon="plus"
      style="width: 26px; height: 26px"
      class="rounded-full bg-primary-700 text-white text-3xl absolute bottom-0 right-2 p-2"
      :class="disabled ? 'hidden' : ''"
    />

    <img
      v-if="viewExternal && url"
      :src="url"
      class="object-cover w-32 h-32 rounded-full"
    />
    <img
      v-else-if="viewLocal"
      :src="image"
      class="object-cover w-32 h-32 rounded-full"
    />

    <input
      type="file"
      name="file"
      :accept="accept"
      class="absolute top-0 bottom-0 right-0 left-0 z-10 h-full w-full opacity-0 cursor-pointer"
      :disabled="disabled"
      @change="getImage($event)"
      ref="file"
    />
    <icon icon="image" class="text-4xl" v-if="!viewLocal && !url" />
    <div class="py-4" v-if="!viewLocal && !url">Subir imagen</div>
  </div>
</template>

<script>
export default {
  name: "t-upload",
  data() {
    return {
      image: null,
      viewLocal: false,
      viewExternal: false
    }
  },
  props: {
    url: {
      type: String,
      required: false
    },
    file: {
      type: String | File,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    accept: {
      type: String,
      required: false,
      default: "img/*"
    }
  },
  methods: {
    logClick() {},
    getImage(event) {
      let data = event.target.files[0]
      if (!data) return
      if (data.type.includes("image")) {
        this.$emit("update:file", event.target.files[0])
        let reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = (e) => {
          this.image = e.target.result
          this.viewExternal = false
          this.viewLocal = true
          this.upload()
        }
      } else {
        this.$snotify.error(
          "Por favor ingrese un formato válido",
          "Formato invalido",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
    },
    upload() {
      this.$emit("send")
    },
    triggerClick() {
      this.$refs.file.click()
    }
  },
  created() {
    if (this.url) this.viewExternal = true
  }
}
</script>
