export default [
  {
    path: "/noticias",
    name: "Noticias",
    component: () => import("@/views/notice/List.vue"),
    meta: {
      rule: "isReporter",
      authRequired: true,
      pageTitle: "Noticias",
      breadcrumb: [{ title: "Noticias", url: "/noticias" }],
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/noticias/crear",
    name: "Crear noticia",
    component: () => import("@/views/notice/Create.vue"),
    meta: {
      rule: "isReporter",
      authRequired: true,
      pageTitle: "Crear torneo",
      breadcrumb: [
        { title: "Noticias", url: "/noticias" },
        { title: "Crear", url: "/noticias/crear" }
      ],
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/noticias/:id/editar",
    name: "Editar noticia",
    component: () => import("@/views/notice/Edit.vue"),
    meta: {
      rule: "isReporter",
      authRequired: true,
      pageTitle: "Noticias",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Noticias", url: "/noticias" },
          { title: "Editar", url: `/noticias/${route.params.id}/editar` }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  }
]
