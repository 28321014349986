import state from "./moduleAcademyCategoryState"
import actions from "./moduleAcademyCategoryActions"
import getters from "./moduleAcademyCategoryGetters"
import mutations from "./moduleAcademyCategoryMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
