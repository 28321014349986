export default (object) => {
  return Object.keys(object).reduce((formData, key) => {
    if (
      object[key] !== null &&
      object[key] !== "null" &&
      object[key] !== "" &&
      key !== "id" &&
      object[key] !== "undefined"
    )
      formData.append(
        key,
        Array.isArray(object[key]) ? JSON.stringify(object[key]) : object[key]
      )
    return formData
  }, new FormData())
}
