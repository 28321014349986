export default {
  ADD_ITEM(state, item) {
    state.elements.push(item)
  },

  UPDATE_ITEM(state, item) {
    let index = state.elements.findIndex((index) => index.id == item.id)
    Object.assign(state.elements[index], item)
  },

  SET_ITEMS(state, items) {
    state.elements = items
  },

  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },

  SET_ITEM(state, item) {
    state.element = item
  },
  UPDATE_FIELD(state, { id, field, value }) {
    let element
    let index = state.elements.findIndex((x) => x.id === id)
    if (index !== -1) {
      element = state.elements[index]
      element[field] = value
      Object.assign(state.elements[index], element)
    }
    index = state.unpaginated_elements.findIndex((x) => x.id === id)
    if (index !== -1) {
      element = state.unpaginated_elements[index]
      element[field] = value
      Object.assign(state.unpaginated_elements[index], element)
    }
  }
}
