import state from "./moduleTournamentRegionState"
import actions from "./moduleTournamentRegionActions"
import getters from "./moduleTournamentRegionGetters"
import mutations from "./moduleTournamentRegionMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
