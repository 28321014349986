import state from "./moduleCoachState"
import actions from "./moduleCoachActions"
import getters from "./moduleCoachGetters"
import mutations from "./moduleCoachMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
