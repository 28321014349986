<template>
  <t-card class="w-full" variant="form">
    <div class="flex justify-between px-3 bg-white py-4 rounded-t-md mt-3">
      <p class="uppercase font-bold text-sm my-auto text-center">
        {{ title }}
      </p>
      <div class="flex items-center">
        <t-button
          type="button"
          class="mr-2"
          variant="error"
          @click="$router.go(-1)"
          >Volver</t-button
        >
        <t-button type="button" @click="submit" :disabled="isLoading || disableSave">
          Guardar
          <t-loader v-if="isLoading" extraClass="ml-2" />
        </t-button>
      </div>
    </div>

    <div class="flex flex-col px-4 bg-gray-100 pb-8">
      <slot name="fields"></slot>
    </div>
  </t-card>
</template>

<script>
export default {
  name: "basic-form",
  props: {
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disableSave: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    submit() {
      this.$emit("save")
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>
