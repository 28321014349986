export default [
  {
    path: "/entrenadores",
    name: "Entrenadores",
    component: () => import("@/views/coach/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Entrenadores",
      module: "coaches",
      breadcrumb: [{ title: "Entrenadores", url: "/entrenadores" }],
      topColor: "#E87B35",
      icon: "chalkboard-teacher"
    }
  },
  {
    path: "/entrenadores/crear",
    name: "Crear entrenador",
    component: () => import("@/views/coach/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear torneo",
      module: "coaches",
      breadcrumb: [
        { title: "Entrenadores", url: "/entrenadores" },
        { title: "Crear", url: "/entrenadores/crear" }
      ],
      topColor: "#E87B35",
      icon: "chalkboard-teacher"
    }
  },
  {
    path: "/entrenadores/:id/editar",
    name: "Editar entrenador",
    component: () => import("@/views/coach/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Entrenadores",
      module: "coaches",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Entrenadores", url: "/entrenadores" },
          { title: "Editar", url: `/entrenadores/${route.params.id}/editar` }
        ]
      },
      topColor: "#E87B35",
      icon: "chalkboard-teacher"
    }
  }
]
