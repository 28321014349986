export default {
  setGameActions({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEM_ACTIONS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }
}
