/* eslint-disable no-async-promise-executor */
import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"

export default {
  create({ commit }, { payload, route, module }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(route, getFormData(payload))
        .then(({ data: response }) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getItems(
    { commit },
    { route, module = null, noPaginate = false, isMaster = false, params = {} }
  ) {
    let url = `${module}/SET_ITEMS`
    if (noPaginate) url += "_NO_PAGINATE"

    return new Promise(async (resolve, reject) => {
      await axios
        .get(isMaster ? "/master" + route : route, {
          params,
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          if (module)
            commit(
              url,
              response.data.data ? response.data.data : response.data,
              {
                root: true
              }
            )
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getItem({ commit }, { id, route, module }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(`${route}/${id}`)
        .then(({ data: response }) => {
          if (module) commit(`${module}/SET_ITEM`, response.data, { root: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  update({ commit }, { payload, route }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`${route}/${payload.id}`, getFormData(payload), {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          try {
            resolve(response)
          } catch (error) {
            console.log(error)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  changeStatus({ commit }, { payload, route, module }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`${route}/change_status/${payload.id}/${payload.status}`)
        .then(({ data: response }) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  updateField({ commit }, { id, field, value, module }) {
    return new Promise(async (resolve, reject) => {
      try {
        commit(`${module}/UPDATE_FIELD`, { id, field, value }, { root: true })
        resolve({ id, field, value })
      } catch (error) {
        reject(error)
      }
    })
  }
}
