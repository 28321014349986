import state from "./moduleTournamentCategoryState"
import actions from "./moduleTournamentCategoryActions"
import getters from "./moduleTournamentCategoryGetters"
import mutations from "./moduleTournamentCategoryMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
