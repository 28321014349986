export default [
  {
    path: "/torneos",
    name: "Torneos",
    component: () => import("@/views/tournament/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Torneos",
      module: "tournaments",
      breadcrumb: [{ title: "Torneos", url: "/torneos" }],
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/crear",
    name: "Crear torneo",
    component: () => import("@/views/tournament/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear torneo",
      module: "tournaments",
      breadcrumb: [
        { title: "Torneos", url: "/torneos" },
        { title: "Crear", url: "/torneos/crear" }
      ],
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/:id/editar",
    name: "Editar torneo",
    component: () => import("@/views/tournament/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Torneos",
      module: "tournaments",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Torneos", url: "/torneos" },
          { title: "Editar", url: `/torneos/${route.params.id}/editar` }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/:id/categorias",
    name: "Categorias de torneos",
    component: () => import("@/views/tournament/categories/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Categorías",
      module: "tournaments",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Torneos", url: "/torneos" },
          {
            title: "Categorías",
            url: "/torneos/" + route.params.id + "/categorias"
          }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/:id/categorias/crear",
    name: "Crear categoria de torneo",
    component: () => import("@/views/tournament/categories/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear categoría de torneo",
      module: "tournaments",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Torneos", url: "/torneos" },
          {
            title: "Categorías",
            url: "/torneos/" + route.params.id + "/categorias"
          },
          {
            title: "Crear",
            url: "/torneos/" + route.params.id + "/categorias/crear"
          }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/:id/categorias/:id2/academias",
    name: "Editar categoria de torneo",
    component: () => import("@/views/tournament/categories/TeamsList.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Equipos de categoría",
      module: "tournaments",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Torneos", url: "/torneos" },
          {
            title: "Categorías",
            url: "/torneos/" + route.params.id + "/categorias"
          },
          {
            title: "Academias",
            url:
              "/torneos/" +
              route.params.id +
              "/categorias/" +
              route.params.id2 +
              "/academias"
          }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/:id/categorias/:id2/onceideal/crear",
    name: "Crear once ideal",
    component: () => import("@/views/tournament/categories/eleven/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear once ideal",
      module: "tournaments",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Torneos", url: "/torneos" },
          {
            title: "Categorías",
            url: "/torneos/" + route.params.id + "/categorias"
          },
          {
            title: "11 Ideal",
            url:
              "/torneos/" +
              route.params.id +
              "/categorias/" +
              route.params.id2 +
              "/academias"
          },
          {
            title: "Crear",
            url:
              "/torneos/" +
              route.params.id +
              "/categorias/" +
              route.params.id2 +
              "/onceideal/crear"
          }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/:id/categorias/:id2/onceideal/:id3/detalles",
    name: "Detalles once ideal",
    component: () => import("@/views/tournament/categories/eleven/Details.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Detalles once ideal",
      module: "tournaments",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Torneos", url: "/torneos" },
          {
            title: "Categorías",
            url: "/torneos/" + route.params.id + "/categorias"
          },
          {
            title: "11 ideal",
            url:
              "/torneos/" +
              route.params.id +
              "/categorias/" +
              route.params.id2 +
              "/academias"
          },
          {
            title: "Detalles",
            url:
              "/torneos/" +
              route.params.id +
              "/categorias/" +
              route.params.id2 +
              "/onceideal/" +
              route.params.id3 +
              "/detalles"
          }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  },
  {
    path: "/torneos/:id/categorias/:id2/onceideal/:id3/editar",
    name: "Editar once ideal",
    component: () => import("@/views/tournament/categories/eleven/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Editar once ideal",
      module: "tournaments",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Torneos", url: "/torneos" },
          {
            title: "Categorías",
            url: "/torneos/" + route.params.id + "/categorias"
          },
          {
            title: "11 ideal",
            url:
              "/torneos/" +
              route.params.id +
              "/categorias/" +
              route.params.id2 +
              "/academias"
          },
          {
            title: "Editar",
            url:
              "/torneos/" +
              route.params.id +
              "/categorias/" +
              route.params.id2 +
              "/onceideal/" +
              route.params.id3 +
              "/editar"
          }
        ]
      },
      topColor: "#D5A434",
      icon: "trophy"
    }
  }
]
