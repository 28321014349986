export default [
  {
    path: "/subcategorias",
    name: "Subcategorias",
    component: () => import("@/views/subcategory/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Subcategorías",
      module: "subcategories",
      breadcrumb: [{ title: "Subcategorías", url: "/subcategorias" }],
      topColor: "#D5A434",
      icon: "chess"
    }
  },
  {
    path: "/subcategorias/crear",
    name: "Crear subcategoria",
    component: () => import("@/views/subcategory/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear subcategoría",
      module: "subcategories",
      breadcrumb: [
        { title: "Subcategorias", url: "/subcategorias" },
        { title: "Crear", url: "/subcategorias/crear" }
      ],
      topColor: "#D5A434",
      icon: "chess"
    }
  },
  {
    path: "/subcategorias/:id/editar",
    name: "Editar subcategoria",
    component: () => import("@/views/subcategory/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Subcategorías",
      module: "subcategories",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Subcategorías", url: "/subcategorias" },
          { title: "Editar", url: `/subcategorias/${route.params.id}/editar` }
        ]
      },
      topColor: "#D5A434",
      icon: "chess"
    }
  }
]
