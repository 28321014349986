export default {
  SET_USER(state, user) {
    state.user = user
  },

  LOGIN(state, token) {
    state.isAuthenticated = true
    window.localStorage.setItem("access_token", token)
  },

  LOGOUT(state) {
    state.isAuthenticated = false
    state.userRole = "public"
    window.localStorage.removeItem("access_token")
    state.user = null
  },
  SET_IMAGE(state, image) {
    state.user.image_profile = image
  },
  DELETE_PROPERTY(state, { id, property }) {
    let index = state.user[property].findIndex((p) => p.id === id)
    if (index) {
      state.user[property].splice(index, 1)
    }
  },
  SET_USER_LOGGED_ROLE(state, idRole) {
    state.userRole = state.roleEnum[idRole]
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  VIEW_NOTIFICATION(state, id) {
    let idx = state.notifications.findIndex((el) => el.id === id)
    state.notifications[idx].viewed = 1
  },
  VIEW_ALL_NOTIFICATIONS(state) {
    state.notifications.forEach((element) => {
      element.viewed = 1
    })
  }
}
