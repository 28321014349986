export default [
  {
    path: "/jugadores",
    name: "Jugadores",
    component: () => import("@/views/player/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Jugadores",
      module: "players",
      breadcrumb: [{ title: "Jugadores", url: "/jugadores" }],
      topColor: "#55B585",
      icon: "users"
    }
  },
  {
    path: "/jugadores/crear",
    name: "Crear jugador",
    component: () => import("@/views/player/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear jugador",
      module: "players",
      breadcrumb: [
        { title: "Jugadores", url: "/jugadores" },
        { title: "Crear", url: "/jugadores/crear" }
      ],
      topColor: "#55B585",
      icon: "users"
    }
  },
  {
    path: "/jugadores/:id/editar",
    name: "Editar jugador",
    component: () => import("@/views/player/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Jugadores",
      module: "players",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Jugadores", url: "/jugadores" },
          { title: "Editar", url: `/jugadores/${route.params.id}/editar` }
        ]
      },
      topColor: "#55B585",
      icon: "users"
    }
  }
]
