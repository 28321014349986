import state from "./moduleAcademyState"
import actions from "./moduleAcademyActions"
import getters from "./moduleAcademyGetters"
import mutations from "./moduleAcademyMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
