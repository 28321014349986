import state from "./moduleGameState"
import actions from "./moduleGameActions"
import getters from "./moduleGameGetters"
import mutations from "./moduleGameMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
