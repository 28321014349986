import state from "./moduleNoticeState"
import actions from "./moduleNoticeActions"
import getters from "./moduleNoticeGetters"
import mutations from "./moduleNoticeMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
