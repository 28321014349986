import state from "./moduleOtherTitleState"
import actions from "./moduleOtherTitleActions"
import getters from "./moduleOtherTitleGetters"
import mutations from "./moduleOtherTitleMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
