import state from "./moduleTournamentState"
import actions from "./moduleTournamentActions"
import getters from "./moduleTournamentGetters"
import mutations from "./moduleTournamentMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
