import Vue from "vue"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faEyeSlash,
  faEye,
  faEdit,
  faTrashAlt,
  faPlus,
  faToggleOff,
  faToggleOn,
  faBars,
  faImage,
  faCheck,
  faCheckCircle,
  faUser,
  faUserMd,
  faGraduationCap,
  faAngleRight,
  faHome,
  faThLarge,
  faPrescriptionBottleAlt,
  faPills,
  faPrescription,
  faStethoscope,
  faPrescriptionBottle,
  faHeadset,
  faIdBadge,
  faEnvelope,
  faRuler,
  faShieldAlt,
  faSignOutAlt,
  faUsers,
  faClock,
  faFilePdf,
  faFileExcel,
  faCalendarAlt,
  faBell,
  faTimes,
  faExclamationTriangle,
  faPhone,
  faFileContract,
  faUpload,
  faDownload,
  faCapsules,
  faAngleLeft,
  faStar,
  faFileMedical,
  faHospitalUser,
  faSearch,
  faCaretDown,
  faCaretUp,
  faClipboardList,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faEllipsisV,
  faHeart,
  faCommentAlt,
  faComments,
  faBookOpen,
  faIdCard,
  faCog,
  faTv,
  faTrophy,
  faChess,
  faFlagCheckered,
  faChalkboardTeacher,
  faVolleyballBall,
  faNewspaper,
  faArrowUp,
  faArrowDown,
  faMinus,
  faCheckSquare,
  faMinusSquare,
  faUserCheck,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(
  faEyeSlash,
  faEye,
  faEdit,
  faTrashAlt,
  faPlus,
  faToggleOff,
  faToggleOn,
  faBars,
  faImage,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faMinusSquare,
  faUser,
  faUserMd,
  faGraduationCap,
  faAngleRight,
  faHome,
  faThLarge,
  faPrescriptionBottleAlt,
  faPills,
  faPrescription,
  faStethoscope,
  faPrescriptionBottle,
  faHeadset,
  faIdBadge,
  faIdCard,
  faEnvelope,
  faRuler,
  faShieldAlt,
  faSignOutAlt,
  faUsers,
  faClock,
  faFilePdf,
  faFileExcel,
  faCalendarAlt,
  faBell,
  faTimes,
  faExclamationTriangle,
  faPhone,
  faFileContract,
  faUpload,
  faDownload,
  faCapsules,
  faAngleLeft,
  faStar,
  faFileMedical,
  faHospitalUser,
  faSearch,
  faCaretDown,
  faCaretUp,
  faClipboardList,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faChevronLeft,
  faBookOpen,
  faCog,
  faEllipsisV,
  faHeart,
  faCommentAlt,
  faComments,
  faTv,
  faUserCheck,
  faTrophy,
  faChess,
  faFlagCheckered,
  faChalkboardTeacher,
  faVolleyballBall,
  faNewspaper,
  faArrowUp,
  faArrowDown,
  faMinus
)

Vue.component("icon", FontAwesomeIcon)
