export default {
  user: null,
  isAuthenticated: false,
  userRole: null,
  notifications: [],
  isUserLoggedIn: () => {
    return localStorage.getItem("access_token") && this.isAuthenticated
  },
  roleEnum: {
    1: "admin",
    2: "reporter",
    3: "scorer"
  }
}
