const createWebSocketPlugin = (socket) => {
  return (store) => {
    store.$socket = socket

    socket.on("user_connected", (data) => {})

    socket.on("error", (data) => {
      console.log("socket error", data)
    })
  }
}

export default createWebSocketPlugin
