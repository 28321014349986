import Vue from "vue"

const timeUnits = {
  seconds: "segundos",
  minutes: "minutos",
  hours: "horas",
  days: "días",
  weeks: "semanas",
  years: "años"
}

Vue.filter("transformDate", (value, type = "short") => {
  if (value) {
    const date = new Date(value)
    date.setDate(date.getDate() + 1)
    let options = { year: "numeric", month: "short" }
    if (type === "large") {
      options = { year: "numeric", month: "short", day: "numeric" }
    }
    if (type === "long") {
      options = { year: "numeric", month: "long", day: "numeric" }
    }
    if (type === "reduced") {
      options = { year: "numeric", month: "numeric", day: "numeric" }
    }

    return new Intl.DateTimeFormat("es-ES", options).format(date)
  }
  return ""
})

Vue.filter("transformHour", (value) => {
  value = value
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value]

  if (value.length > 1) {
    // If value format correct
    value = value.slice(1) // Remove full string match value
    value[5] = +value[0] < 12 ? " AM" : " PM" // Set AM/PM
    value[0] = +value[0] % 12 || 12 // Adjust hours

    return value.join("")
  }

  return ""
})

Vue.filter("transformDatetime", (value) => {
  if (value) {
    const date = new Date(value)
    date.setDate(date.getDate() + 1)
    let options = { year: "numeric", month: "short" }
    if (type === "large") {
      options = { year: "numeric", month: "short", day: "numeric" }
    }
    if (type === "long") {
      options = { year: "numeric", month: "long", day: "numeric" }
    }
    if (type === "reduced") {
      options = { year: "numeric", month: "numeric", day: "numeric" }
    }

    let datex = new Intl.DateTimeFormat("es-ES", options).format(date)

    value = value
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value]

    if (value.length > 1) {
      // If value format correct
      value = value.slice(1) // Remove full string match value
      value[5] = +value[0] < 12 ? " AM" : " PM" // Set AM/PM
      value[0] = +value[0] % 12 || 12 // Adjust hours

      let hourx = value.join("")

      return datex + " - " + hourx
    }

    return datex
  }

  return ""
})

Vue.filter("fromNow", (value) => {
  const fromNowObject = timeFromNow(value + "Z")
  const fromNowVanilla = `Hace ${fromNowObject.time} ${timeUnits}`

  return fromNowVanilla
})

const timeFromNow = function (time) {
  // Get timestamps
  var unixTime = new Date(time).getTime()
  if (!unixTime) return
  var now = new Date().getTime()

  // Calculate difference
  var difference = unixTime / 1000 - now / 1000

  // Setup return object
  var tfn = {}

  // Check if time is in the past, present, or future
  tfn.when = "now"
  if (difference > 0) {
    tfn.when = "future"
  } else if (difference < -1) {
    tfn.when = "past"
  }

  difference = Math.abs(difference)

  // Calculate time unit
  if (difference / (60 * 60 * 24 * 365) > 1) {
    // Years
    tfn.unitOfTime = "years"
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 365))
  } else if (difference / (60 * 60 * 24 * 7) > 1) {
    // Weeks
    tfn.unitOfTime = "weeks"
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 7))
  } else if (difference / (60 * 60 * 24) > 1) {
    // Days
    tfn.unitOfTime = "days"
    tfn.time = Math.floor(difference / (60 * 60 * 24))
  } else if (difference / (60 * 60) > 1) {
    // Hours
    tfn.unitOfTime = "hours"
    tfn.time = Math.floor(difference / (60 * 60))
  } else if (difference / 60 > 1) {
    // Hours
    tfn.unitOfTime = "minutes"
    tfn.time = Math.floor(difference / 60)
  } else {
    // Seconds
    tfn.unitOfTime = "seconds"
    tfn.time = Math.floor(difference)
  }
  return tfn
}
