export default {
  getUser: (state) => {
    return state.user
  },
  getNotifications: (state) => {
    return state.notifications
  },
  getUserRole: (state) => {
    return state.userRole
  },
  countNotificationsUnreaded: (state) => {
    if (state.notifications) {
      let x = state.notifications.filter((el) => el.viewed == 0).length
      if (x > 1000) {
        x = (x / 1000).toFixed(2) + "K"
      }
      return x
    } else {
      return 0
    }
  }
}
