export default {
  elements: [],
  unpaginated_elements: [],
  element: null,
  roles: {
    1: "Administrador",
    2: "Reportero",
    3: "Anotador"
  }
}
