import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"

export default {
  changePassword(context, { id, payload }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`/user/change_password/${id}`, getFormData(payload))
        .then(({ data: response }) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
