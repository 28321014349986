<template>
  <span
    class="w-5 h-5 border-4 border-teal-600 rounded-full inline-block t-loader"
    :class="extraClass"
  ></span>
</template>

<script>
export default {
  name: "t-loader",
  props: {
    extraClass: {
      type: String,
      required: false
    }
  }
}
</script>

<style>
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.t-loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>