import SocketIO from "socket.io-client"

var url_domain = window.location.host

//default url
var socket_url = "https://apilif.myticktap.com"

if (url_domain == "ligainternacional.ticktaps.com")
  socket_url = "https://apilif.ticktaps.com"

const socket = SocketIO(socket_url, {
  transports: ["websocket"]
})

export default socket
