import state from "./moduleTitleState"
import actions from "./moduleTitleActions"
import getters from "./moduleTitleGetters"
import mutations from "./moduleTitleMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
