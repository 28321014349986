export default [
   
    {
      path: "/noticia/detalle/:slug",
      name: "Detalle de noticia",
      component: () => import("@/views/notice/Preview.vue"),
      meta: {
        rule: "isPublic"
      }
    }
  ]
  