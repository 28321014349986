import isMobilePhone from "validator/es/lib/isMobilePhone"
export const es = {
  es: {
    attributes: {
      name: "nombre",
      first_name: "nombre",
      last_name: "apellido",
      full_name: "nombre completo",
      telephone: "teléfono",
      address: "dirección",
      dni: "cédula de identidad",
      email: "correo electrónico",
      password: "contraseña",
      passwordConfirm: "confirmar contraseña",
      code: "código de verificación",
      old_password: "contraseña actual",
      new_password: "nueva contraseña",
      address: "dirección",
      birthdate: "fecha de nacimiento",
      phone: "teléfono",
      profile_picture: "foto de perfil",
      url_logo: "foto del escudo",
      url_field: "foto de la sede",
      coordinator: "coordinador",
      address1: "dirección",
      address2: "dirección",
      categories: "categorías",
      date: "fecha",
      hour: "hora",
      height: "altura",
      weight: "peso",
      favorite_player: "jugador favorito",
      favorite_team: "equipo favorito",
      position: "posición",
      jersey_number: "número de camisa",
      mother_first_name: "nombre de la madre",
      father_first_name: "nombre del padre",
      mother_last_name: "apellido de la madre",
      father_last_name: "apellido del padre",

      mother_dni: "cédula de la madre",
      father_dni: "cédula del padre",
      mother_address: "dirección de la madre",
      father_address: "dirección del padre",
      mother_phone: "teléfono de la madre",
      father_phone: "teléfono del padre",
      mother_local_phone: "teléfono de habitación de la madre",
      father_local_phone: "teléfono de habitación del padre",
      mother_email: "correo de la madre",
      father_email: "correo del padre",
      profession: "profesión",
      mother_profession: "cargo que ocupa",
      father_profession: "cargo que ocupa",
      mother_workplace: "empresa donde labora",
      father_workplace: "empresa donde labora",
      url_profile_picture: "foto de perfil",
      url_dni_picture: "foto de la cédula",
      url_carnet_type: "foto tipo carnet",
      url_carnet_picture: "foto del carnet",
      id_carnet_type: "tipo de carnet",
      gender: "sexo",
      skillful_foot: "pie dominante",
      category: "categoría",
      category0: "categoría",
      category1: "categoría",
      category2: "categoría",
      category3: "categoría",
      category4: "categoría",
      category5: "categoría",
      category6: "categoría",
      category7: "categoría",
      category8: "categoría",
      category9: "categoría",
      category10: "categoría",
      category11: "categoría",
      category12: "categoría",
      category13: "categoría",
      category14: "categoría",
      category15: "categoría",
      id_category: "categoría",
      id_subcategory: "subcategoría",
      id_academy: "academia",
      representative_type: "tipo de representante",
      date_from: "fecha de inicio",
      date_to: "fecha de fin",
      round: "ronda",
      id_tournament: "torneo",
      id_team: "equipo",
      id_home_team: "equipo local",
      id_visit_team: "equipo visitante",
      start_hour: "hora de inicio",
      id_player: "jugador 1",
      id_goalkeeper: "jugador 2",
      id_action: "acción",
      minute: "minuto",
      id_scorer: "anotador",
      home_goals: "goles del equipo local",
      visit_goals: "goles del equipo visitante",
      home_penalty_goals: "goles de penalty de locales",
      visit_penalty_goals: "goles de penalty de visitantes",
      url_picture: "foto del equipo",
      coaches: "entrenador",
      titles: "título",
      year: "año",
      player: "jugador",
      player0: "jugador",
      player1: "jugador",
      player2: "jugador",
      player3: "jugador",
      player4: "jugador",
      player5: "jugador",
      player6: "jugador",
      player7: "jugador",
      player8: "jugador",
      player9: "jugador",
      player10: "jugador",
      players: "jugador",
      observation: "observación",
      observation: "descripción",
      type: "tipo",
      academy: "academia",
      academies: "academias",
      academy0: "academia",
      academy1: "academia",
      academy2: "academia",
      academy3: "academia",
      academy4: "academia",
      academy5: "academia",
      academy6: "academia",
      academy7: "academia",
      academy8: "academia",
      academy9: "academia",
      academy10: "academia",
      academy11: "academia",
      academy12: "academia",
      academy13: "academia",
      academy14: "academia",
      academy15: "academia",
      id_role: "rol"
    },
    messages: {
      confirmed: () => "Las contraseñas no coinciden"
    }
  }
}

export const alpha_spaces_rule = {
  getMessage: (field) =>
    `El campo ${field} solo debe contener letras y espacios.`,
  validate: (value) => /^[A-Záéíóúñ' ]+$/i.test(value)
}

export const amount_rule = {
  getMessage: (field) => `El campo ${field} debe ser una cantidad válida.`,
  validate: (value) => !isNaN(value)
}

export const simple_required = {
  getMessage: () => `Este campo es requerido`,
  validate: (value) => {
    return {
      valid: ["", null, undefined].indexOf(value) === -1,
      data: {
        required: true // or false
      }
    }
  }
}

export const simple_email = {
  getMessage: () => `Este campo debe ser un correo válido`,
  validate: (value) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
}

export const year_length = {
  getMessage: (field) => `El campo ${field} debe contener 4 dígitos.`,
  validate: (value) => value.length === 4
}

export const alpha_symbol_rule = {
  getMessage: (field) =>
    `El campo ${field} solo debe contener letras, espacios y los símbolos: ( ) /`,
  validate: (value) => /^[A-Záéíóúñ'()/ ¿?]+$/i.test(value)
}

export const phone_rule = {
  getMessage() {
    return `El campo debe ser un teléfono válido.`
  },
  validate(value) {
    return isMobilePhone(value)
  }
}
