export default {
  getItems: (state, getters, rootState) => (module, property) => {
    return [...rootState[module][property]]
  },
  getItemsMaster: (state, getters, rootState) => (module, property) => {
    return [...rootState[module][property]]
      .slice()
      .filter((record) => record !== null && record.id !== 1)
    //.sort((firstItem, secondItem) => firstItem.id - secondItem.id)
  },

  getItem: (state, getters, rootState) => (module, property) => {
    return rootState[module][property]
  }
}
