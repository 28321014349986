export default [
  {
    path: "/inicio",
    name: "Home",
    component: () => import("@/views/auth/Home.vue"),
    meta: {
      rule: "isPublic"
    }
  },
  {
    path: "/iniciar_sesion",
    name: "Iniciar sesión",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      rule: "isPublic"
    }
  },
  {
    path: "/registro_academia",
    name: "Academy Register",
    component: () => import("@/views/auth/Academy/Create.vue"),
    meta: {
      rule: "isPublic"
    }
  },
  {
    path: "/registro_entrenador",
    name: "Coach Register",
    component: () => import("@/views/auth/Coach/Create.vue"),
    meta: {
      rule: "isPublic"
    }
  },
  {
    path: "/registro_jugador",
    name: "Player Register",
    component: () => import("@/views/auth/Player/Create.vue"),
    meta: {
      rule: "isPublic"
    }
  },
  {
    path: "/reestablecer",
    name: "Reset password",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      rule: "isPublic"
    }
  },
 
]
