import state from "./moduleCardTypeState"
import actions from "./moduleCardTypeActions"
import getters from "./moduleCardTypeGetters"
import mutations from "./moduleCardTypeMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
