import Spanish from "vue-tailwind/dist/l10n/es"
import TInput from "vue-tailwind/dist/t-input"
import TTextarea from "vue-tailwind/dist/t-textarea"
import TSelect from "vue-tailwind/dist/t-select"
import TRadio from "vue-tailwind/dist/t-radio"
import TCheckbox from "vue-tailwind/dist/t-checkbox"
import TButton from "vue-tailwind/dist/t-button"
import TInputGroup from "vue-tailwind/dist/t-input-group"
import TRichSelect from "vue-tailwind/dist/t-rich-select"
import TDatepicker from "vue-tailwind/dist/t-datepicker"
import TCard from "vue-tailwind/dist/t-card"
import TModal from "vue-tailwind/dist/t-modal"
import TDropdown from "vue-tailwind/dist/t-dropdown"
import TTable from "vue-tailwind/dist/t-table"
import TPagination from "vue-tailwind/dist/t-pagination"
import TDialog from "vue-tailwind/dist/t-dialog"
import TToggle from "vue-tailwind/dist/t-toggle"
import TAlert from "vue-tailwind/dist/t-alert"
export const settings = {
  "t-toggle": {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200",
        wrapperChecked:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-green-1100 focus:outline-none focus:ring-opacity-50",
        wrapperDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed",
        wrapperCheckedDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-1100 focus:outline-none focus:ring-opacity-50",
        button:
          "inline-block absolute transform translate-x-0 transition ease-in-out duration-200",
        buttonChecked:
          "inline-block absolute transform translate-x-full transition ease-in-out duration-200",
        checkedPlaceholder: "inline-block",
        uncheckedPlaceholder: "inline-block"
      },
      classes: {
        wrapper:
          "bg-gray-200 rounded-full border-2 border-transparent focus:ring-2 focus:ring-green-1100 focus:outline-none focus:ring-opacity-50",
        wrapperChecked: "bg-green-1100 rounded-full",
        wrapperDisabled:
          "bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-1100 focus:outline-none focus:ring-opacity-50",
        wrapperCheckedDisabled: "bg-blue-1100",
        button:
          "h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs",
        buttonChecked:
          "h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-1100 text-xs",
        checkedPlaceholder:
          "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
        uncheckedPlaceholder:
          "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs"
      },
      variants: {
        danger: {
          wrapperChecked: "bg-red-1100 rounded-full",
          wrapperCheckedDisabled: "bg-red-1100 rounded-full"
        },
        success: {
          wrapperChecked: "bg-green-1100 rounded-full",
          wrapperCheckedDisabled: "bg-green-1100 rounded-full"
        },
        box: {
          wrapper:
            "bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-1100 focus:outline-none focus:ring-opacity-50",
          wrapperChecked: "bg-blue-1100 rounded-sm",
          wrapperCheckedDisabled: "bg-blue-1100 rounded-sm",
          button:
            "h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs",
          buttonChecked:
            "h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-1100 text-xs",
          checkedPlaceholder:
            "rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs",
          uncheckedPlaceholder:
            "rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs"
        }
      }
    }
  },
  "t-input": {
    component: TInput,
    props: {
      fixedClasses:
        "block w-full px-3 py-2 transition duration-100 ease-in-out border border-1 rounded-lg shadow-xl focus:border-blue-1100 focus:border-2 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-black placeholder-gray-400 bg-white border-gray-300",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-gray-400 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
        warning:
          "border-yellow-500 bg-yellow-50 placeholder-gray-400 text-yellow-900"
      }
    }
  },
  "t-textarea": {
    component: TTextarea,
    props: {
      fixedClasses:
        "block w-full px-3 py-2 transition duration-100 ease-in-out border rounded-lg shadow-xl focus:border-red-800 focus:ring-2 focus:border-red-800 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-black placeholder-gray-400 bg-white border-gray-300",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
        warning:
          "border-yellow-500 bg-yellow-50 placeholder-gray-400 text-yellow-900"
      }
    }
  },
  "t-select": {
    component: TSelect,
    props: {
      fixedClasses:
        "block w-full pl-3 pr-10 py-2 transition duration-100 ease-in-out border border-1 rounded-lg shadow-xl focus:border-primary-800 focus:border-2 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-black placeholder-gray-400 bg-white border-gray-300",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
        warning:
          "border-yellow-300 bg-yellow-50 placeholder-gray-400 text-yellow-900"
      }
    }
  },
  "t-radio": {
    component: TRadio,
    props: {
      fixedClasses:
        "transition duration-100 ease-in-out shadow-sm focus:border-blue-1100 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-green-500 border-gray-300",
      variants: {
        error: "text-red-500 border-red-300",
        success: "text-green-500 border-green-300",
        warning: "text-yellow-500 border-gray-300"
      }
    }
  },
  "t-checkbox": {
    component: TCheckbox,
    props: {
      fixedClasses:
        "transition duration-100 ease-in-out rounded shadow-xl focus:border-blue-1100 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-green-500 border-gray-300 ",
      variants: {
        error: "text-red-500 border-red-300",
        success: "text-green-500 border-green-300",
        warning: "text-yellow-500 border-gray-300 "
      }
    }
  },
  "t-button": {
    component: TButton,
    props: {
      fixedClasses:
        "flex justify-center transition duration-100 ease-in-out focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed shadow-sm rounded-lg items-center",
      classes:
        "px-4 py-2 text-white bg-primary-700 border-0 hover:bg-primary-900 focus:ring-red-500",
      variants: {
        error:
          "px-4 py-2 text-white bg-red-1100 border-0 hover:bg-red-900 focus:ring-red-700",
        warning:
          "px-4 py-2 text-white bg-yellow-1100 border-0 hover:bg-yellow-900 focus:ring-yellow-700",
        success:
          "px-4 py-2 text-white bg-green-600 border border-transparent rounded shadow-sm hover:bg-green-700 focus:ring-green-600",

        link: "text-primary-700 font-bold hover:text-primary-900 shadow-none focus:ring-0 p-0",
        gray: "px-4 py-2 text-white bg-gray-20 border border-transparent rounded shadow-sm hover:bg-gray-30 focus:bg-gray-30",
        "outline-danger":
          "px-5 py-3 bg-transparent border border-primary-700 text-primary-800 rounded shadow-sm hover:bg-primary-700 hover:text-white focus:bg-primary-700 focus:text-white",
        "outline-warning":
          "px-4 py-2 bg-transparent border border-yellow-500 text-yellow-500 rounded shadow-sm hover:bg-yellow-500 hover:text-white focus:bg-yellow-500 focus:text-white",
        "outline-success":
          "px-4 py-2 bg-transparent border border-green-500 text-green-500 rounded shadow-sm hover:bg-green-500 hover:text-white focus:bg-green-500 focus:text-white"
      }
    }
  },
  "t-input-group": {
    component: TInputGroup,
    props: {
      fixedClasses: {
        label: "block text-left font-bold",
        feedback: "text-sm w-fit px-2 ml-2 -mb-1",
        description: "text-gray-400 text-sm"
      },
      classes: {
        wrapper: "my-2 md:my-1",
        label: "",
        body: "",
        feedback: "text-gray-400",
        description: "text-gray-400"
      },
      variants: {
        danger: {
          label: "text-red-500",
          feedback: "bg-red-500 text-white rounded-lg rounded-t-none ",
          wrapper: "",
          body: ""
        },
        success: {
          label: "text-green-500",
          feedback: "bg-green-500 text-white rounded-lg rounded-t-none "
        },
        warning: {
          wrapper: "",
          label: "text-yellow-700",
          body: "",
          feedback:
            "border-yellow-300 bg-yellow-300 placeholder-gray-400 text-yellow-700 border rounded-lg rounded-t-none border-t-0 ",
          description: "text-gray-400"
        }
      }
    }
  },
  "t-rich-select": {
    component: TRichSelect,
    props: {
      fixedClasses: {
        wrapper: "relative",
        buttonWrapper: "inline-block relative w-full",
        selectButton:
          "w-full flex text-left justify-between items-center px-3 py-2 text-black transition duration-100 ease-in-out border rounded-lg shadow-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
        selectButtonLabel: "block truncate",
        selectButtonTagWrapper: "flex flex-wrap overflow-hidden",
        selectButtonTag:
          "bg-blue-500 block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded shadow-sm text-sm text-white transition white-space-no m-0.5 max-w-full overflow-hidden h-8 flex items-center",
        selectButtonTagText: "px-3",
        selectButtonTagDeleteButton:
          "-ml-1.5 h-full hover:bg-blue-600 hover:shadow-sm inline-flex items-center px-2 transition",
        selectButtonTagDeleteButtonIcon: "w-3 h-3",
        selectButtonPlaceholder: "block truncate",
        selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
        selectButtonClearButton:
          "rounded flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out",
        selectButtonClearIcon: "fill-current h-3 w-3",
        dropdown:
          "absolute w-full z-10 -mt-1 absolute border-b border-l border-r rounded-b shadow-sm z-20",
        dropdownFeedback: "",
        loadingMoreResults: "",
        optionsList: "overflow-auto",
        searchWrapper: "inline-block w-full",
        searchBox: "inline-block w-full",
        optgroup: "",
        option: "cursor-pointer",
        disabledOption: "opacity-50 cursor-not-allowed",
        highlightedOption: "cursor-pointer",
        selectedOption: "cursor-pointer",
        selectedHighlightedOption: "cursor-pointer",
        optionContent: "flex justify-between items-center px-3 py-2",
        optionLabel: "truncate block",
        selectedIcon: "fill-current h-4 w-4",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: ""
      },
      classes: {
        wrapper: "",
        buttonWrapper: "",
        selectButton: "bg-white border-gray-300",
        selectButtonLabel: "",
        selectButtonTagWrapper: "-mx-2 -my-2.5 py-1 pr-8 ",
        selectButtonTagWrapper: "-mx-2 -my-2.5 py-1 pr-8",
        selectButtonTag:
          "bg-blue-500 block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded shadow-sm text-sm text-white transition white-space-no m-0.5 max-w-full overflow-hidden h-8 flex items-center",
        selectButtonTagText: "px-3",
        selectButtonTagDeleteButton:
          "-ml-1.5 h-full hover:bg-blue-600 hover:shadow-sm inline-flex items-center px-2 transition",
        selectButtonTagDeleteButtonIcon: "",
        selectButtonPlaceholder: "text-gray-400",
        selectButtonIcon: "text-gray-600",
        selectButtonClearButton: "hover:bg-blue-100 text-gray-600",
        selectButtonClearIcon: "",
        dropdown: "bg-white border-gray-300",
        dropdownFeedback: "pb-2 px-3 text-gray-400 text-sm",
        loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
        optionsList: "",
        searchWrapper: "p-2 placeholder-gray-400",
        searchBox:
          "px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300",
        optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
        option: "",
        disabledOption: "",
        highlightedOption: "bg-blue-100",
        selectedOption:
          "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
        selectedHighlightedOption:
          "font-semibold bg-gray-100 bg-blue-600 font-semibold text-white",
        optionContent: "flex justify-between items-center px-3 py-2",
        optionLabel: "",
        selectedIcon: "",
        enterClass: "opacity-0",
        enterActiveClass: "transition ease-out duration-100",
        enterToClass: "opacity-100",
        leaveClass: "opacity-100",
        leaveActiveClass: "transition ease-in duration-75",
        leaveToClass: "opacity-0"
      },
      variants: {
        danger: {
          selectButton: "border-red-300 bg-red-50 text-red-900",
          selectButtonPlaceholder: "text-red-200",
          selectButtonIcon: "text-red-500",
          selectButtonClearButton: "hover:bg-red-200 text-red-500",
          dropdown: "bg-red-50 border-red-300"
        },
        success: {
          selectButton: "border-green-300 bg-green-50 text-green-900",
          selectButtonIcon: "text-green-500",
          selectButtonClearButton: "hover:bg-green-200 text-green-500",
          dropdown: "bg-green-50 border-green-300"
        },
        dark: {
          selectButton:
            "bg-jevi-dark border-gray-300 hover:border-primary-800 text-white",
          selectButtonIcon: "text-white",
          selectButtonClearButton: "hover:bg-transparent text-white",
          dropdown: "border-primary-300 bg-jevi-dark",
          selectButtonPlaceholder: "text-white",
          selectedOption: "text-white",
          selectedHighlightedOption: "text-white",
          selectButtonPlaceholder: "text-white",
          selectButtonIcon: "text-white",
          selectButtonClearButton: "text-white",
          dropdownFeedback: "text-white",
          loadingMoreResults: "text-white"
        },
        variant3: {
          wrapper: "",
          buttonWrapper: "",
          selectButton: "border-yellow-300 bg-yellow-50 text-yellow-900",
          selectButtonLabel: "",
          selectButtonPlaceholder: "text-gray-400",
          selectButtonIcon: "text-gray-600",
          selectButtonClearButton: "hover:bg-yellow-100 text-gray-600",
          selectButtonClearIcon: "",
          dropdown: "bg-yellow-50 border-yellow-300",
          dropdownFeedback: "pb-2 px-3 text-gray-400 text-sm",
          loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
          optionsList: "",
          searchWrapper: "p-2 placeholder-gray-400",
          searchBox:
            "px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300",
          optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
          option: "",
          disabledOption: "",
          highlightedOption: "bg-yellow-100",
          selectedOption:
            "font-semibold bg-gray-100 bg-yellow-500 font-semibold text-white",
          selectedHighlightedOption:
            "font-semibold bg-gray-100 bg-yellow-600 font-semibold text-white",
          optionContent: "flex justify-between items-center px-3 py-2",
          optionLabel: "",
          selectedIcon: "",
          enterClass: "opacity-0",
          enterActiveClass: "transition ease-out duration-100",
          enterToClass: "opacity-100",
          leaveClass: "opacity-100",
          leaveActiveClass: "transition ease-in duration-75",
          leaveToClass: "opacity-0"
        }
      }
    }
  },
  "t-datepicker": {
    component: TDatepicker,
    props: {
      fixedClasses: {
        navigator: "flex",
        navigatorViewButton: "flex items-center",
        navigatorViewButtonIcon: "flex-shrink-0 h-5 w-5",
        navigatorViewButtonBackIcon: "flex-shrink-0 h-5 w-5",
        navigatorLabel: "flex items-center py-1",
        navigatorPrevButtonIcon: "h-6 w-6 inline-flex",
        navigatorNextButtonIcon: "h-6 w-6 inline-flex",
        inputWrapper: "relative",
        viewGroup: "inline-flex flex-wrap",
        view: "w-64",
        calendarDaysWrapper: "grid grid-cols-7",
        calendarHeaderWrapper: "grid grid-cols-7",
        monthWrapper: "grid grid-cols-4",
        yearWrapper: "grid grid-cols-4",
        input:
          "block w-full px-3 py-2 transition duration-100 ease-in-out border rounded-lg shadow-xl focus:border-primary-800 focus:border-2 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
        clearButton:
          "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
        clearButtonIcon: "fill-current h-3 w-3"
      },
      classes: {
        wrapper: "flex flex-col",
        dropdownWrapper: "relative z-20",
        dropdown:
          "origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1",
        enterClass: "opacity-0 scale-95",
        enterActiveClass: "transition transform ease-out duration-100",
        enterToClass: "opacity-100 scale-100",
        leaveClass: "opacity-100 scale-100",
        leaveActiveClass: "transition transform ease-in duration-75",
        leaveToClass: "opacity-0 scale-95",
        inlineWrapper: "",
        inlineViews: "rounded bg-white border mt-1 inline-flex",
        inputWrapper: "",
        input: "text-black placeholder-gray-400 border-gray-300",
        clearButton:
          "hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600",
        clearButtonIcon: "",
        viewGroup: "",
        view: "",
        navigator: "pt-2 px-3",
        navigatorViewButton:
          "transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100",
        navigatorViewButtonIcon: "fill-current text-gray-400",
        navigatorViewButtonBackIcon: "fill-current text-gray-400",
        navigatorViewButtonMonth: "text-gray-700 font-semibold",
        navigatorViewButtonYear: "text-gray-500 ml-1",
        navigatorViewButtonYearRange: "text-gray-500 ml-1",
        navigatorLabel: "py-1",
        navigatorLabelMonth: "text-gray-700 font-semibold",
        navigatorLabelYear: "text-gray-500 ml-1",
        navigatorPrevButton:
          "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed",
        navigatorNextButton:
          "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed",
        navigatorPrevButtonIcon: "text-gray-400",
        navigatorNextButtonIcon: "text-gray-400",
        calendarWrapper: "px-3 pt-2",
        calendarHeaderWrapper: "",
        calendarHeaderWeekDay:
          "uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center",
        calendarDaysWrapper: "",
        calendarDaysDayWrapper: "w-full h-8 flex flex-shrink-0 items-center",
        otherMonthDay:
          "text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed",
        emptyDay: "",
        inRangeFirstDay:
          "text-sm bg-primary-800 text-white w-full h-8 rounded-l-full",
        inRangeLastDay:
          "text-sm bg-primary-800 text-white w-full h-8 rounded-r-full",
        inRangeDay:
          "text-sm bg-primary-700 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed",
        selectedDay:
          "text-sm rounded-full w-8 h-8 mx-auto bg-primary-800 text-white disabled:opacity-50 disabled:cursor-not-allowed",
        activeDay:
          "text-sm rounded-full bg-primary-700 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
        highlightedDay:
          "text-sm rounded-full bg-primary-700 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
        day: "text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary-700 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed",
        today:
          "text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary-700 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500",
        monthWrapper: "px-3 pt-2",
        selectedMonth: "text-sm rounded w-full h-12 mx-auto bg-primary-700 ",
        activeMonth: "text-sm rounded w-full h-12 mx-auto bg-primary-700",
        month:
          "text-sm rounded w-full h-12 mx-auto hover:bg-primary-700 hover:text-white",
        yearWrapper: "px-3 pt-2",
        year: "text-sm rounded w-full h-12 mx-auto hover:bg-primary-700 hover:text-white",
        selectedYear:
          "text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white",
        activeYear: "text-sm rounded w-full h-12 mx-auto bg-primary-700",
        // Time selector *Since 2.2*
        timepickerWrapper: "flex items-center px-4 py-2 space-x-2",
        timepickerTimeWrapper: "flex items-center space-x-2",
        timepickerTimeFieldsWrapper:
          "bg-gray-100 rounded-md w-full text-right flex items-center border border-gray-100 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        timepickerOkButton:
          "text-blue-600 text-sm uppercase font-semibold transition duration-100 ease-in-out border border-transparent focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded cursor-pointer",
        timepickerInput:
          "text-center w-8 border-transparent bg-transparent p-0 h-6 text-sm transition duration-100 ease-in-out border border-transparent focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded",
        timepickerTimeLabel: "flex-grow text-sm text-gray-500",
        timepickerAmPmWrapper:
          "relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        timepickerAmPmWrapperChecked:
          "relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        timepickerAmPmWrapperDisabled:
          "relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed",
        timepickerAmPmWrapperCheckedDisabled:
          "relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed",
        timepickerAmPmButton:
          "absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded shadow",
        timepickerAmPmButtonChecked:
          "absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-full bg-white rounded shadow",
        timepickerAmPmCheckedPlaceholder:
          "flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm",
        timepickerAmPmUncheckedPlaceholder:
          "flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm"
      },
      variants: {
        danger: {
          input: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
          clearButton: "hover:bg-red-200 text-red-500"
        }
      },
      locale: Spanish
    }
  },
  "t-card": {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: "rounded-md shadow-lg bg-white text-black",
        header: "font-medium tracking-tighter text-lg rounded-t text-black",
        footer: "p-2 rounded-b border-green-200 text-black border-t"
      },
      classes: {
        wrapper: "bg-white border-gray-100",
        body: "px-3",
        header: "border-gray-100",
        footer: "border-gray-100"
      },
      variants: {
        form: {
          body: "px-0 mb-5 rounded-b-md"
        }
      }
    }
  },
  "t-modal": {
    component: TModal,
    props: {
      fixedClasses: {
        overlay:
          "z-50 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50",
        wrapper: "relative mx-auto z-50 max-w-xl px-3 py-12 mt-8",
        modal: "overflow-visible z-40 relative rounded",
        body: "p-3",
        header: "border-b p-3 rounded-t",
        footer: "p-3 rounded-b",
        close:
          "flex items-center justify-center rounded-full absolute right-0 top-0 mr-1 mt-2 h-8 w-8 transition duration-100 ease-in-out focus:outline-none "
      },
      classes: {
        overlay: "bg-gray-900",
        wrapper: "",
        modal: "bg-white shadow",
        body: "p-3 text-gray-900",
        header: "border-gray-100 text-primary-700 text-xl font-bold",
        footer: "bg-gray-100 text-gray-800",
        close: "bg-red-500 text-white hover:bg-red-700 right-2 top-3",
        closeIcon: "fill-current h-4 w-4 ",
        overlayEnterClass: "opacity-0",
        overlayEnterActiveClass: "transition ease-out duration-100",
        overlayEnterToClass: "opacity-100",
        overlayLeaveClass: "opacity-100",
        overlayLeaveActiveClass: "transition ease-in duration-75",
        overlayLeaveToClass: "opacity-0",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: ""
      },
      variants: {
        danger: {
          overlay: "bg-red-900",
          header: "border-red-100 text-red-500 text-xl font-bold",
          close:
            "bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border right-4 top-4",
          modal: "bg-white border border-red-100 shadow-lg",
          footer: "bg-red-50"
        },
        xl: {
          wrapper: "max-w-xl"
        }
      }
    }
  },
  "t-dropdown": {
    component: TDropdown,
    props: {
      fixedClasses: {
        button:
          "flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out rounded-full border border-transparent rounded shadow-sm focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
        wrapper: "inline-flex flex-col ",
        dropdownWrapper: "relative z-10",
        dropdown: "absolute rounded shadow-md mt-1",
        enterClass: "opacity-0 scale-95",
        enterActiveClass: "transition transform ease-out duration-100",
        enterToClass: "opacity-100 scale-100",
        leaveClass: "opacity-100 scale-100",
        leaveActiveClass: "transition transform ease-in duration-75",
        leaveToClass: "opacity-0 scale-95"
      },
      classes: {
        button: "focus:border-blue-1100 focus:ring-2 focus:ring-blue-1100",
        dropdown: "bg-white w-56 left-auto right-0 top-3 mt-3",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: "",
        dropdownWrapper: "",
        wrapper: ""
      },
      variants: {
        danger: {
          button: "bg-red-500 hover:bg-red-600",
          dropdown: "bg-red-100",
          enterActiveClass: ""
        },
        tooltip: {
          dropdown:
            "bg-blue-1100 bottom-0 left-6 right-auto text-white text-xs rounded-md p-2 w-28 mt-0",
          enterActiveClass: "transition transform ease-out duration-100",
          leaveActiveClass: "transition transform duration-75"
        },
        tooltipLeft: {
          dropdown:
            "bg-blue-1100 right-0 text-white text-xs rounded-md p-1 w-28 mt-0",
          enterActiveClass: "transition transform ease-out duration-75",
          leaveActiveClass: ""
        }
      }
    }
  },
  "t-table": {
    component: TTable,
    props: {
      classes: {
        table:
          "min-w-full divide-y divide-gray-100 shadow-sm border-gray-200 border rounded-md overflow-hidden",
        thead: "border-b border-t",
        theadTr: "",
        theadTh: "px-3 py-2 font-semibold text-left text-sm",
        tbody: "bg-white divide-y divide-gray-100",
        tr: "",
        td: "px-3 py-2 whitespace-no-wrap text-sm",
        tfoot: "",
        tfootTr: "",
        tfootTd: ""
      },
      variants: {}
    }
  },
  "t-pagination": {
    component: TPagination,
    props: {
      classes: {
        wrapper: "table border-collapse text-center bg-white mx-auto shadow-sm",
        element:
          "w-8 h-8 border border-gray-200 table-cell hover:border-green-100",
        activeElement:
          "w-8 h-8 border border-blue-500 table-cell hover:border-blue-600",
        disabledElement: "w-8 h-8 border border-gray-200 table-cell",
        ellipsisElement: "w-8 h-8 border border-gray-200 hidden md:table-cell",
        activeButton:
          "bg-green-500 w-full h-full text-white hover:bg-green-600",
        disabledButton: "opacity-25 w-full h-full cursor-not-allowed",
        button: "pagInactiveBtn hover:bg-green-100 w-full h-full",
        ellipsis: ""
      },
      variants: {
        rounded: {
          wrapper: "mx-auto text-center flex space-x-2 justify-center",
          element: "w-8 h-8 rounded-full bg-white",
          activeElement: "w-8 h-8 rounded-full",
          disabledElement: "w-8 h-8 rounded-full",
          ellipsisElement: "w-8 h-8 rounded-full hidden md:inline",
          activeButton:
            "pagActiveBtn w-full h-full rounded-full text-white underline font-bold",
          disabledButton:
            "text-white pagInactiveBtn opacity-25 w-full h-full rounded-full cursor-not-allowed",
          button:
            "text-white pagInactiveBtn hover:bg-gray-200 rounded-full w-full h-full",
          ellipsis: ""
        }
      }
    }
  },
  "t-alert": {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: "relative flex items-center p-4 border-l-4  rounded shadow-sm",
        body: "flex-grow",
        close:
          "absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        closeIcon: "fill-current h-4 w-4"
      },
      classes: {
        wrapper: "bg-blue-50 border-blue-500",
        body: "text-blue-700",
        close: "text-blue-500 hover:bg-blue-200"
      },
      variants: {
        danger: {
          wrapper: "bg-red-50 border-red-500",
          body: "text-red-700",
          close: "text-red-500 hover:bg-red-200"
        },
        success: {
          wrapper: "bg-green-50 border-green-500",
          body: "text-green-700",
          close: "text-green-500 hover:bg-green-200"
        },
        warning: {
          wrapper: "bg-yellow-100 border-yellow-500 mb-4",
          body: "text-yellow-700",
          close: "text-yellow-500 hover:bg-yellow-200"
        }
      }
    }
  },
  "t-dialog": {
    component: TDialog,
    props: {
      fixedClasses: {
        overlay:
          "overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed z-40 bg-opacity-50",
        wrapper: "relative mx-auto",
        modal: "overflow-visible relative",
        close:
          "flex items-center justify-center  rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50",
        dialog: "overflow-visible relative",
        iconWrapper:
          "flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto",
        icon: "w-6 h-6",
        okButton:
          "block px-4 py-2 text-white transition duration-100 ease-in-out border border-transparent rounded-lg shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs",
        buttons: "p-3 flex space-x-4 justify-center bg-gray-100 rounded-b",
        title: "text-xl font-bold text-center"
      },
      classes: {
        close: "bg-gray-100 text-gray-600 hover:bg-gray-200",
        closeIcon: "fill-current h-4 w-4",
        overlay: "bg-black",
        wrapper: "z-50 max-w-lg px-3 py-12",
        dialog: "bg-white shadow rounded text-left",
        body: "p-3 space-y-3",
        buttons: "bg-gray-100",
        iconWrapper: "bg-gray-100",
        icon: "text-gray-500",
        content: "w-full flex justify-center flex-col",
        titleWrapper: "",
        title: "text-red-1100",
        textWrapper: "text-left w-full",
        text: "",
        cancelButton:
          "block px-4 py-2 transition duration-100 ease-in-out text-white bg-gray-400 hover:bg-gray-500 rounded-lg shadow-sm hover:bg-gray-100 focus:border-gray-100 focus:ring-2 focus:ring-gray-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs",
        okButton: "bg-blue-1100 hover:bg-blue-900",
        inputWrapper: "mt-3 flex items-center space-x-3",
        input:
          "block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-700 focus:ring-2 focus:ring-blue-700 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full",
        select:
          "block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full",
        radioWrapper: "flex items-center space-x-2",
        radio:
          "text-green-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
        radioText: "",
        checkboxWrapper: "flex items-center space-x-2",
        checkbox:
          "text-green-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
        checkboxText: "",
        errorMessage: "text-red-500 block text-sm",
        busyWrapper:
          "absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full",
        busyIcon: "animate-spin h-6 w-6 fill-current text-gray-500",
        overlayEnterClass: "opacity-0",
        overlayEnterActiveClass: "transition ease-out duration-100",
        overlayEnterToClass: "opacity-100",
        overlayLeaveClass: "opacity-100",
        overlayLeaveActiveClass: "transition ease-in duration-75",
        overlayLeaveToClass: "opacity-0",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: ""
      },
      variants: {
        danger: {
          overlay: "bg-red-100",
          okButton: "bg-red-500 hover:bg-red-600",
          iconWrapper: "bg-red-50",
          buttons: "bg-white",
          icon: "text-red-500",
          cancelButton:
            "block px-4 py-2 transition duration-100 ease-in-out bg-white hover:bg-gray-200 rounded-lg shadow-sm hover:bg-gray-100 border border-gray-200 focus:border-gray-400 focus:ring-2 focus:ring-gray-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs",
          title: "text-gray-900"
        },
        horizontal: {
          body: "p-3 flex space-x-3",
          title: "text-green-500"
        }
      }
    }
  }
}
