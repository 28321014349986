<template>
  <div
    v-if="empty || loading"
    class="w-full flex flex-col items-center flex-grow rounded-lg justify-center"
  >
    <template v-if="empty">
      <img
        v-if="!hideImg"
        class="w-16 mb-3"
        src="@/assets/img/no_results.svg"
        alt="No se encontraron resultados"
      />
      <p class="text-primary-700 p-2 text-center">
        <slot>{{ text }}</slot>
      </p>
    </template>
    <t-loader v-if="loading" extraClass="border-primary-700 w-8 h-8" />
  </div>
</template>
<script>
export default {
  name: "no-results",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    empty: {
      type: Boolean,
      default: false
    },
    hideImg: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "No se encontraron resultados"
    }
  }
}
</script>
<style lang="scss"></style>
