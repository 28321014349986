import state from "./modulePlayerState"
import actions from "./modulePlayerActions"
import getters from "./modulePlayerGetters"
import mutations from "./modulePlayerMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
