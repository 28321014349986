import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import createWebSocketPlugin from "./plugin"
import socket from "../socket/socket"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const websocketPlugin = createWebSocketPlugin(socket)

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
import moduleAuth from "./auth/moduleAuth"
import moduleGlobal from "./global/moduleGlobal"
import moduleUser from "./user/moduleUser"
import moduleNotice from "./notice/moduleNotice"
import moduleNoticeCategory from "./noticeCategory/moduleNoticeCategory"
import moduleTournament from "./tournament/moduleTournament"
import moduleTournamentCategory from "./tournamentCategory/moduleTournamentCategory"
import moduleTournamentAcademy from "./tournamentAcademy/moduleTournamentAcademy"
import moduleTournamentRegion from "./tournamentRegion/moduleTournamentRegion"
import moduleAcademy from "./academy/moduleAcademy"
import moduleAcademyCategory from "./academyCategory/moduleAcademyCategory"
import modulePlayer from "./player/modulePlayer"
import moduleCategory from "./category/moduleCategory"
import moduleSubcategory from "./subcategory/moduleSubcategory"
import moduleCoach from "./coach/moduleCoach"
import moduleCardType from "./cardType/moduleCardType"
import moduleGame from "./game/moduleGame"
import moduleAction from "./action/moduleAction"
import moduleTitle from "./title/moduleTitle"
import moduleOtherTitle from "./otherTitle/moduleOtherTitle"
import moduleRole from "./role/moduleRole"
import moduleEleven from "./eleven/moduleEleven"

const store = new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    action: moduleAction,
    academy: moduleAcademy,
    academyCategory: moduleAcademyCategory,
    category: moduleCategory,
    subcategory: moduleSubcategory,
    cardType: moduleCardType,
    coach: moduleCoach,
    global: moduleGlobal,
    player: modulePlayer,
    tournament: moduleTournament,
    tournamentCategory: moduleTournamentCategory,
    tournamentAcademy: moduleTournamentAcademy,
    tournamentRegion: moduleTournamentRegion,
    noticeCategory: moduleNoticeCategory,
    user: moduleUser,
    role: moduleRole,
    game: moduleGame,
    notice: moduleNotice,
    title: moduleTitle,
    otherTitle: moduleOtherTitle,
    eleven: moduleEleven
  },
  plugins: [vuexLocal.plugin, websocketPlugin]
})

export default store
