import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router/index.js"
import store from "@/store/store.js"

Vue.use(AclInstaller)

let initialRole = "public"

const userRole = store.state.auth.userRole
if (userRole) initialRole = userRole

const acl = new AclCreate({
  initial: initialRole,
  notfound: "/403",
  router,
  acceptLocalRules: true,
  globalRules: {
    isAdmin: new AclRule("admin").generate(),
    isReporter: new AclRule("reporter").or("admin").generate(),
    isScorer: new AclRule("scorer").or("admin").generate(),
    isPublic: new AclRule("public")
      .or("admin")
      .or("reporter")
      .or("scorer")
      .generate(),
    isGlobal: new AclRule("admin")
      .or("public")
      .or("reporter")
      .or("scorer")
      .generate()
  }
})

export default acl
