import state from "./moduleNoticeCategoryState"
import actions from "./moduleNoticeCategoryActions"
import getters from "./moduleNoticeCategoryGetters"
import mutations from "./moduleNoticeCategoryMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
