/* eslint-disable no-async-promise-executor */
import Vue from "vue"
import router from "@/router"
import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"

export default {
  login({ commit, state }, { user, aclChangeRole }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/auth/login", getFormData(user))
        .then(({ data: response }) => {
          if (response.code === 200) {
            let { data: user } = response
            aclChangeRole(state.roleEnum[user.id_role])
            commit("LOGIN", user.access_token.token)
            commit("SET_USER", user)
            commit("SET_USER_LOGGED_ROLE", user.id_role)
            router.push("/dashboard")
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  setCurrentUser({ commit }, user) {
    return new Promise(async (resolve) => {
      commit("SET_USER", user)
      resolve(user)
    })
  },
  getUserLogged({ state, commit }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(`/user/${state.user.id}`)
        .then(async ({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            let { data: user } = response
            commit("SET_USER", user)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getNotifications({ state, commit }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(
          `/notification/get_all?order_key=id&order_value=desc&id_user=${state.user.id}`
        )
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            let { data } = response
            commit("SET_NOTIFICATIONS", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  viewNotification({ commit }, { id }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`/notification/change_viewed/${id}/1`)
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            commit("VIEW_NOTIFICATION", id)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  viewAllNotification({ state, commit }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`/notification/change_viewed/${state.user.id}/all/1`)
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            commit("VIEW_ALL_NOTIFICATIONS")
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  recovery(context, payload) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/security/recover_password", getFormData(payload))
        .then(({ data: response }) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setPassword(context, { payload, token }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`/security/set_new_password/${token}`, getFormData(payload))
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            router.push("/iniciar_sesion")
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit }, aclChangeRole) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          "/auth/logout",
          {},
          {
            headers: {
              Authorization:
                "Bearer " + window.localStorage.getItem("access_token")
            }
          }
        )
        .then(({ data: response }) => {
          aclChangeRole("public")
          commit("LOGOUT")
          window.localStorage.removeItem("vuex")
          router.push("/").catch(() => {})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeImageProfile({ commit }, { id, payload }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `/user/change_profile_picture/${id}`,
          getFormData({ image_profile: payload })
        )
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            let { data: user } = response
            commit("SET_IMAGE", user.image_profile)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
