import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store/store.js"

Vue.use(VueRouter)

import routesAuth from "./routes/auth.js"
import routesDashboard from "./routes/dashboard.js"
import routesTournament from "./routes/tournament.js"
import routesAcademy from "./routes/academy.js"
import routesPlayer from "./routes/player.js"
import routesCoach from "./routes/coach.js"
import routesCategory from "./routes/category.js"
import routesSubcategory from "./routes/subcategory.js"
import routesGame from "./routes/game.js"
import routesNotice from "./routes/notice.js"
import routesPublics from "./routes/publics.js"
import routesUser from "./routes/user.js"
import routesTitle from "./routes/title.js"

const routes = [
  {
    path: "",
    name: "Inicio",
    redirect: "/inicio",
    component: () => import("../layout/main/Main.vue"),
    children: [
      routesDashboard,
      routesTournament,
      routesAcademy,
      routesPlayer,
      routesCoach,
      routesCategory,
      routesSubcategory,
      routesGame,
      routesNotice,
      routesTitle,
      routesUser
    ].flat()
  },
  {
    path: "/auth",
    component: () => import("../views/auth/Index.vue"),
    children: [routesAuth].flat()
  },
  {
    path: "/ver",
    component: () => import("../views/auth/Publics.vue"),
    children: [routesPublics].flat()
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/error/404.vue"),
    meta: {
      rule: "isGlobal"
    }
  },
  {
    path: "/403",
    name: "notfound",
    component: () => import("../views/error/403.vue"),
    meta: {
      rule: "*"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.rule == "isPublic") {
    return next()
  }
  if (to.meta.authRequired) {
    if (!store.state.auth.isAuthenticated) {
      router.push({ path: "/iniciar_sesion" })
    }
  } else {
    if (store.state.auth.isAuthenticated) {
      if (to.path === "/inicio") {
        router.push("/dashboard")
      }
    }
  }
  return next()
})

export default router
