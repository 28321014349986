<template>
  <div
    :style="`height: ${height}`"
    class="cursor-pointer relative w-full h-full text-center flex justify-center items-center flex-col rounded-lg overflow-hidden p-3"
    :class="backgroundColor"
  >
    <icon
      v-if="isNotImage"
      icon="file-contract"
      class="text-primary-800 text-2xl"
    />
    <img
      v-else-if="viewExternal"
      :src="externalUrl"
      class="object-scale-down w-full h-full"
      style="filter: drop-shadow(0 0 0.2rem #a0a0a0)"
      @error="notImage"
    />
    <img
      v-else-if="viewLocal"
      :src="image"
      class="object-scale-down h-full my-auto"
      @error="notImage"
    />
    <icon v-else icon="upload" class="text-primary-800 text-xl" />

    <input
      type="file"
      name="file"
      :accept="accept"
      class="absolute top-0 bottom-0 right-0 left-0 z-10 h-full w-full opacity-0 cursor-pointer"
      :disabled="disabled"
      @change="getImage($event)"
      ref="file"
    />

    <div v-if="!url && !viewExternal">{{ placeholder }}</div>

    <t-button class="z-20" v-if="isNotImage && !viewLocal" @click="openFile"
      >Ver archivo</t-button
    >
  </div>
</template>

<script>
export default {
  name: "t-upload-file",
  data() {
    return {
      image: null,
      name: "",
      url: null,
      viewLocal: false,
      viewExternal: false,
      isNotImage: false
    }
  },
  props: {
    file: {
      type: String | File,
      default: null
    },
    loading: {
      type: Boolean,
      required: false
    },
    externalUrl: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "Subir archivo"
    },
    height: {
      type: String,
      default: "150px"
    },
    backgroundColor: {
      type: String,
      default: "bg-gray-100"
    },
    accept: {
      type: String,
      default: "*"
    }
  },
  methods: {
    openFile() {
      const newWindow = window.open(
        this.externalUrl,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    },
    notImage() {
      this.isNotImage = true
    },
    getImage(event) {
      let data = event.target.files[0]
      let fileExt = data.name.split(".").pop() || "file"
      this.name = data.name
      this.$emit("update:file", event.target.files[0])
      let reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])

      try {
        this.url = require(`@/assets/img/files/${fileExt}.png`)
      } catch (err) {
        this.url = require(`@/assets/img/files/file.png`)
      }
      reader.onload = (e) => {
        this.isNotImage = false
        this.image = e.target.result
        this.viewExternal = false
        this.viewLocal = true
      }
    },
    triggerClick() {
      this.$refs.file.click()
    }
  },
  created() {
    if (this.externalUrl) this.viewExternal = true
  },
  watch: {
    externalUrl() {
      if (this.externalUrl) this.viewExternal = true
    }
  }
}
</script>
