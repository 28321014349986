export default [
  {
    path: "/academias",
    name: "Academias",
    component: () => import("@/views/academy/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumb: [{ title: "Academias", url: "/academias" }],
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/crear",
    name: "Crear academia",
    component: () => import("@/views/academy/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear academia",
      module: "academies",
      breadcrumb: [
        { title: "Academias", url: "/academias" },
        { title: "Crear", url: "/academias/crear" }
      ],
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/editar",
    name: "Editar academia",
    component: () => import("@/views/academy/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Editar", url: `/academias/${route.params.id}/editar` }
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id",
    name: "Detalles de academia",
    component: () => import("@/views/academy/details/Details.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` }
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/crear",
    name: "Crear categoria de academia",
    component: () => import("@/views/academy/details/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          {
            title: "Categoría",
            url: `/academias/${route.params.id}/categorias/crear`
          }
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/:id2/editar",
    name: "Editar categoria de academia",
    component: () => import("@/views/academy/details/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          {
            title: "Categoría",
            url: `/academias/${route.params.id}/categorias/${route.params.id2}/editar`
          }
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/:id2/jugadores",
    name: "Jugadores de categoria de academia",
    component: () => import("@/views/academy/details/players/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          {
            title: "Categoría",
            url: `/academias/${route.params.id}/categorias/${route.params.id2}/jugadores`
          }
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/:id2/jugadores/:id3/editar",
    name: "Editar jugador de categoria de academia",
    component: () => import("@/views/academy/details/players/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          {
            title: "Categoría",
            url: `/academias/${route.params.id}/categorias/${route.params.id2}/jugadores`
          },
          {
            title: "Jugador",
            url: `/academias/${route.params.id}/categorias/${route.params.id2}/jugadores/${route.params.id3}/editar`
          }
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/:id2/jugadores/crear",
    name: "Crear jugador de categoria de academia",
    component: () => import("@/views/academy/details/players/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          {
            title: "Categoría",
            url: `/academias/${route.params.id}/categorias/${route.params.id2}/jugadores`
          },
          {
            title: "Jugador",
            url: `/academias/${route.params.id}/categorias/${route.params.id2}/jugadores/crear`
          }
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/:id2/titulos",
    name: "Titulos de categoria de academia",
    component: () => import("@/views/academy/details/titles/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          { title: "Categoría", url: `/academias/${route.params.id}/categorias/${route.params.id2}/titulos` },
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/:id2/titulos/:id3/editar",
    name: "Editar titulo de categoria de academia",
    component: () => import("@/views/academy/details/titles/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          { title: "Categoría", url: `/academias/${route.params.id}/categorias/${route.params.id2}/titulos` },
          { title: "Título", url: `/academias/${route.params.id}/categorias/${route.params.id2}/titulos/${route.params.id3}/editar` },
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  },
  {
    path: "/academias/:id/categorias/:id2/titulos/crear",
    name: "Crear titulo de categoria de academia",
    component: () => import("@/views/academy/details/titles/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Academias",
      module: "academies",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Academias", url: "/academias" },
          { title: "Detalles", url: `/academias/${route.params.id}` },
          { title: "Categoría", url: `/academias/${route.params.id}/categorias/${route.params.id2}/titulos` },
          { title: "Título", url: `/academias/${route.params.id}/categorias/${route.params.id2}/titulos/crear` },
        ]
      },
      topColor: "#942122",
      icon: "shield-alt"
    }
  }
]
