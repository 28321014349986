import state from "./moduleCategoryState"
import actions from "./moduleCategoryActions"
import getters from "./moduleCategoryGetters"
import mutations from "./moduleCategoryMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
