export default [
  {
    path: "/usuarios",
    name: "Usuarios",
    component: () => import("@/views/user/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Usuarios",
      module: "users",
      breadcrumb: [{ title: "Usuarios", url: "/usuarios" }],
      topColor: "#55B585",
      icon: "users"
    }
  },
  {
    path: "/usuarios/crear",
    name: "Crear usuario",
    component: () => import("@/views/user/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear usuario",
      module: "users",
      breadcrumb: [
        { title: "Usuarios", url: "/usuarios" },
        { title: "Crear", url: "/usuarios/crear" }
      ],
      topColor: "#55B585",
      icon: "users"
    }
  },
  {
    path: "/usuarios/:id/editar",
    name: "Editar usuario",
    component: () => import("@/views/user/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Usuarios",
      module: "users",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Usuarios", url: "/usuarios" },
          { title: "Editar", url: `/usuarios/${route.params.id}/editar` }
        ]
      },
      topColor: "#55B585",
      icon: "users"
    }
  }
]
