export default [
  {
    path: "/titulos",
    name: "Titulos",
    component: () => import("@/views/title/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Títulos",
      module: "titles",
      breadcrumb: [{ title: "Títulos", url: "/titulos" }],
      topColor: "#942122",
      icon: "flag-checkered"
    }
  },
  {
    path: "/titulos/crear",
    name: "Crear titulo",
    component: () => import("@/views/title/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear título",
      module: "titles",
      breadcrumb: [
        { title: "Títulos", url: "/titulos" },
        { title: "Crear", url: "/titulos/crear" }
      ],
      topColor: "#942122",
      icon: "flag-checkered"
    }
  },
  {
    path: "/titulos/:id/editar",
    name: "Editar titulo",
    component: () => import("@/views/title/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Títulos",
      module: "titles",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Títulos", url: "/titulos" },
          { title: "Editar", url: `/titulos/${route.params.id}/editar` }
        ]
      },
      topColor: "#942122",
      icon: "flag-checkered"
    }
  },
  {
    path: "/titulos/entregados/:id/editar",
    name: "Editar titulo entregado",
    component: () => import("@/views/title/rewarded/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Títulos",
      module: "titles",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Títulos", url: "/titulos" },
          { title: "Editar", url: `/titulos/entregados/${route.params.id}/editar` }
        ]
      },
      topColor: "#942122",
      icon: "flag-checkered"
    }
  },
  {
    path: "/titulos/entregados/crear",
    name: "Crear titulo entregado",
    component: () => import("@/views/title/rewarded/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Títulos",
      module: "titles",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Títulos", url: "/titulos" },
          { title: "Editar", url: `/titulos/entregados/crear` }
        ]
      },
      topColor: "#942122",
      icon: "flag-checkered"
    }
  }
]
