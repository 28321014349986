const periodMultiplier = {
  years: 60 * 60 * 24 * 365,
  weeks: 60 * 60 * 24 * 7,
  days: 60 * 60 * 24,
  hours: 60 * 60,
  minutes: 60,
  seconds: 1
}
const diffFrom = (date, period) => {
  const unixTime = new Date(date).getTime()
  if (!unixTime) return
  const now = new Date().getTime()

  const difference = Math.abs(unixTime / 1000 - now / 1000)
  return difference / periodMultiplier[period]
}

export { diffFrom }
