export default [
  {
    path: "/categorias",
    name: "Categorias",
    component: () => import("@/views/category/List.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Categorías",
      module: "categories",
      breadcrumb: [{ title: "Categorías", url: "/categorias" }],
      topColor: "#295DA4",
      icon: "chess"
    }
  },
  {
    path: "/categorias/crear",
    name: "Crear categoria",
    component: () => import("@/views/category/Create.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Crear categoría",
      module: "categories",
      breadcrumb: [
        { title: "Categorias", url: "/categorias" },
        { title: "Crear", url: "/categorias/crear" }
      ],
      topColor: "#295DA4",
      icon: "chess"
    }
  },
  {
    path: "/categorias/:id/editar",
    name: "Editar categoria",
    component: () => import("@/views/category/Edit.vue"),
    meta: {
      rule: "isAdmin",
      authRequired: true,
      pageTitle: "Categorías",
      module: "categories",
      breadcrumbDynamic: (route) => {
        return [
          { title: "Categorías", url: "/categorias" },
          { title: "Editar", url: `/categorias/${route.params.id}/editar` }
        ]
      },
      topColor: "#295DA4",
      icon: "chess"
    }
  }
]
