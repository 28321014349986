import state from "./moduleRoleState"
import actions from "./moduleRoleActions"
import getters from "./moduleRoleGetters"
import mutations from "./moduleRoleMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
