import Vue from "vue"

import TLoader from "./components/Loader.vue"
import TUpload from "./components/Upload.vue"
import TUploadFile from "./components/UploadFile.vue"
import CropperUpload from "./components/CropperUpload.vue"
import BasicTable from "./components/BasicTable.vue"
import BasicForm from "./components/BasicForm.vue"
import NoResults from "./components/NoResults.vue"

Vue.component(TLoader.name, TLoader)
Vue.component(TUpload.name, TUpload)
Vue.component(TUploadFile.name, TUploadFile)
Vue.component(CropperUpload.name, CropperUpload)
Vue.component(BasicTable.name, BasicTable)
Vue.component(BasicForm.name, BasicForm)
Vue.component(NoResults.name, NoResults)
